import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { actions } from "../_redux/AccountRedux";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { getBanks, updateAccount } from "../_redux/AccountCRUD";
import { useErrorHandler } from "react-error-boundary";
import { MiniPreLoader } from "../../Components";

const accountDetailsSchema = Yup.object().shape({
  number: Yup.string().min(10, "Invalid bank account number"),
  name: Yup.string(),
  bank_code: Yup.string(),
  bank_name: Yup.string(),
});

const filterChangedValues = (values, initialValues, ignoredValues = []) => {
  const results = {};
  Object.keys(initialValues).forEach((key) => {
    if (!ignoredValues.includes(key) && initialValues[key] !== values[key]) {
      results[key] = values[key];
    }
  });

  return results;
};

function BankAccountDetails({ 
  fetchBanks,banks,user,fulfillUser,updateToken,
  ...props }) {
  const [pageLoading, setPageLoading] = useState(true);  
  const [loading, setLoading] = useState(false);
  
  const initialValues = {
    name: "",
    number: "",
    bank_code: ""
  };

  const { name, number, bank_code } = user.account || initialValues;
  const accountInfo = { name, number, bank_code };

  const handleError = useErrorHandler();
  const handleSubmit = async (values) => {
    const updatedValues = filterChangedValues(values, accountInfo);
    //  call update route on api
    if (Object.keys(updatedValues).length !== 0) {
      setLoading(true);

      console.log(updatedValues);

      const bank_name = banks.find(bank => bank.code === updatedValues.bank_code).name;

      const { token, data } = await updateAccount({...values, bank_name});

      updateToken(token);
      fulfillUser(data);
      setLoading(false);
    }
  };

  const retrieveBanks = useCallback(async () => {
    try{
      const banks = await getBanks();
      fetchBanks(banks);
      setPageLoading(false);
    }catch(err){
      handleError(err);
      setPageLoading(false);
    }
  }, []);

  useEffect(() => {
    retrieveBanks();
  },[retrieveBanks]);
  
  const formik = useFormik({
    initialValues: accountInfo,
    validationSchema: accountDetailsSchema,
    onSubmit: useCallback(handleSubmit, [handleSubmit]),
  });

  return (
    <MiniPreLoader type="spinningBubbles" color="#005C6B" loading={pageLoading} height={150}>
      <div className="card mb-5 mb-xl-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Bank Account Info</h3>
          </div>
        </div>

        <div id="kt_account_profile_details" className="collapse show">
          <form noValidate className="form">
            <div className="card-body border-top p-9">
              <div className="row mb-6">
                <label className="col-lg-4 col-sm-3 col-form-label fw-bold fs-6">
                  <span>Account Bank</span>
                </label>
                <div className="col-lg-8 col-sm-9">
                  <select
                    className="form-control"
                    name="bank_code"
                    defaultValue={formik.values.bank_code}
                    onChange={formik.handleChange}
                  >
                    {banks.map((bank) => {
                      return (
                        <option key={banks.indexOf(bank)} value={bank.code}>
                          {bank.name || ""}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.bank_code && formik.errors.bank_code && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.bank_code}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span>Account Number</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    name="number"
                    className="form-control form-control-lg form-control-solid"
                    onChange={formik.handleChange}
                    placeholder="Your bank account number"
                    {...(formik.getFieldProps("number") || "")}
                  />
                  {formik.touched.number && formik.errors.number && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.number}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span>Account Name</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Your bank account name"
                    onChange={formik.handleChange}
                    {...formik.getFieldProps("name")}
                    readOnly
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.name}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
                onClick={formik.handleSubmit}
              >
                {!loading && "Save Changes"}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </MiniPreLoader>
  );
}

const mapStateToProps = (state) => {
  const { bankStore, auth } = state;

  return { ...bankStore, ...auth };
};

export default connect(mapStateToProps, {...actions, ...authActions})(BankAccountDetails);
