import React from 'react';
import { Button, Card } from 'react-bootstrap';

function ErrorFallback({error, resetErrorBoundary}) {
    return (
        <Card className={'d-flex justify-content-center align-items-center w-100 h-100'}>
            <p>Oops, something went wrong :)</p>
            <pre>{error.message}</pre>
            <Button onClick={resetErrorBoundary}>Try again</Button>
        </Card>
    )
}

export default ErrorFallback;