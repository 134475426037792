import axios from 'axios';

const {
    REACT_APP_API_USERS_URL,
    REACT_APP_API_TRANSACTION_URL,
    REACT_APP_API_DISPUTE_URL,
} = process.env;

const SIGN_CHAT_IMAGE_UPLOAD_URL = `${REACT_APP_API_DISPUTE_URL}/ping/sign-dispute-chat-image-upload`;

//for functional component request
//include source inorder to cancel request
//incase of unmount component
export const getTransactionByIdForDispute = ({trans_id,source}) => {
    return axios.get(`${REACT_APP_API_TRANSACTION_URL}/${trans_id}`,{
        cancelToken : source.token
    })
}
//for class component request
//don't include source source
export const getTransactionByTransID = trans_id => {
    return axios.get(`${REACT_APP_API_TRANSACTION_URL}/${trans_id}`);
}

export const createDispute = (data) => {
   return axios.post(REACT_APP_API_DISPUTE_URL,data);
}

export const updateDisputeStatus = (dispute_id,data) => {
    return axios.post(`${REACT_APP_API_DISPUTE_URL}/${dispute_id}/resolve`,data);
}

export const refundBuyerOrSeller = ({
                                        _id, //transaction ID
                                        dispute_id,
                                        position, //either as "buyer" or "seller"
}) => {
    return axios.post(`${REACT_APP_API_USERS_URL}/transactions/${_id}/${dispute_id}/disburse/${position}`);
}

export const getDisputeByDisputeID = (id) => {
   return axios.get(`${REACT_APP_API_DISPUTE_URL}/${id}`)
}

export const getMessagesForDispute = (dispute_id,query) => {
    return axios.get(`${REACT_APP_API_DISPUTE_URL}/${dispute_id}/messages?q=${query}`);
}

export const getAllDisputeForSingleUser = ({ userId, query}) => {
    return axios.get(`${REACT_APP_API_DISPUTE_URL}/${userId}/user?${query}`);
}

export const getAllDisputes = (query) => {
    return axios.get(`${REACT_APP_API_DISPUTE_URL}/agent?${query}`);
}


export const getDisputeChatImageUploadSignature = () => {
    return axios.get(SIGN_CHAT_IMAGE_UPLOAD_URL);
}

export async function cloudinaryChatImageUpload(url,file, config){
    try {
        const {
            signature,
            apikey,
            timestamp,
        } = config;
        //create a new axios instance for this request
        //this is required because the default authorization header in
        //the react axios interceptor is intercepting call request
        // external API
        const instance = axios.create();
        const formData = new FormData();
        formData.append('file',file);
        formData.append("api_key", apikey);
        formData.append("timestamp", timestamp);
        formData.append("signature", signature);
        formData.append("eager", "c_pad,h_100,w_100|c_crop,h_400,w_400");
        formData.append("folder", "vahlid_mern/disputes");
        delete instance.defaults.headers.common["authorization"];
        return (await instance.post(url,formData)).data.secure_url;
    }catch (e) {return Promise.reject(e);}
}