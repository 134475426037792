import React from 'react';
import { Card } from 'react-bootstrap';
import ReactLoading from "react-loading";
import PropTypes from "prop-types";

function Loading({
                     type,
                     color,
                     ...props
                 }){
    return (
      <Card className={'d-flex justify-content-center align-items-center w-100 h-100'}>
          <ReactLoading type={type} color={color} height={50} width={50} />
          <p>Loading...</p>
      </Card>
    );
}

Loading.propTypes = {
    type: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
}

export default Loading;