import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
// import ReactPlayer from "react-player";
//importing material-ui
import { Fab, IconButton, Drawer, makeStyles } from "@material-ui/core";
//importing material-ui-icons
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
//importing styles
import "./index.css";
//importing actions
import { actions } from "../../_redux/DisputeRedux";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    drawerPaper: {
        position: "absolute",
        width: "100%",
    },
    paperAnchorBottom: {
        left: "auto",
        right: 0,
        bottom: 0,
        maxHeight: "100%",
        [theme.breakpoints.up("xs")]: {
            top: 52,
        },
        [theme.breakpoints.up("sm")]: {
            top: 65,
        },
        [theme.breakpoints.up("md")]: {
            top: 65,
        },
        [theme.breakpoints.up("lg")]: {
            top: 65,
        },
    },
}));

function DrawerBottom({
                          user,
                          socket,
                          dispute_id,
                          drawerBottom,
                          setDrawerBottom,
                          fileImageUrl,
                          setFileImageUrl,
                          CreateDisputeMessageSuccessfulAction,
                      }) {
    const classes = useStyles();
    const [caption, setCaption] = useState("");
    const { _id, username } = user;
    const handleUpload = (e) => {
        e.preventDefault();
        if (fileImageUrl) {
            const msg = {
                dispute_id,
                author: _id,
                senderName: username,
                photo: fileImageUrl,
                caption,
            };
            socket.current.emit('post-message',msg);
            CreateDisputeMessageSuccessfulAction(msg);
            setFileImageUrl(null);
        }
        setCaption("");
        setDrawerBottom(false);
    };

    const handleDrawerClose = () => {
        setDrawerBottom(false);
    };

    return (
        <div>
            <Drawer
                variant="persistent"
                anchor="bottom"
                open={drawerBottom}
                classes={{
                    paper: classes.drawerPaper,
                    paperAnchorBottom: classes.paperAnchorBottom,
                }}
            >
                <div className="drawerBottom__header">
                    <div className="drawerBottom__header_container">
                        <IconButton onClick={handleDrawerClose}>
                            <CloseIcon />
                        </IconButton>
                        <p>Preview</p>
                    </div>
                </div>

                <div className="drawerBottom__content">
                    <div className="drawerBottom__content_photo">
                        <img src={fileImageUrl} alt="" />
                    </div>
                    <div className="drawerBottom__content_caption">
                        <input
                            type="text"
                            placeholder="Add a caption..."
                            value={caption}
                            onChange={(e) => setCaption(e.target.value)}
                        />
                        <Fab
                            color="primary"
                            aria-label="send"
                            size="large"
                            onClick={handleUpload}
                        >
                            <div className="chat__icon">
                                <SendIcon />
                            </div>
                        </Fab>
                    </div>
                </div>

                <div className="drawerBottom__footer">
                    <div>{fileImageUrl ? <img src={fileImageUrl} alt="" /> : null}</div>
                </div>
            </Drawer>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { user: auth.user };
}


export default connect(mapStateToProps,actions)(DrawerBottom);
