import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {format} from "date-fns";
import { useErrorHandler } from "react-error-boundary";

import {
    getRedemptionsOrReferrals,
} from '../_redux';

import {
    PreLoader,
    RemoteTransactionTable,
} from "../../Components";

function Referral({
                         className,
                         user,
                         ...props
                     }){
    const [loading, setLoading] = useState(true);
    const handleError = useErrorHandler();
    const [tableState, setTableState ] = useState({
        referralsColumns: [
            {
                dataField : '_id',
                text: 'ID',
                hidden: true,
            },
            {

                dataField : 'vahlid_id',
                text: 'Vahlid ID',
            },
            {
                dataField : 'username',
                text : 'Username',
            },
        ],
        referrals: {
            referralsPage: 1,
            referralsData: [],
            referralsCount: 0,
            referralsSizePerPage: 10,
            referralsLoading: false,
        },
        redemptionColumns: [
            {
                dataField : '_id',
                text: 'ID',
                hidden: true,
            },
            {
                dataField : 'status',
                text: 'Status',
            },
            {
                dataField : 'pointsRedeemed',
                text : 'Points Redeemed',
            },
            {
                dataField: 'redemptionDate',
                text: 'Redemption Date',
                formatter: (cell,row,rowIndex) => (
                    <>
                        <span
                            className="text-muted d-block font-weight-bold text-hover-primary">
                            {format(new Date(row.redemptionDate), 'MMMM dd, yyyy')}
                        </span>
                        <span
                            className="text-muted d-block font-weight-bold text-hover-primary">
                            {format(new Date(row.redemptionDate), 'h:m a')}
                        </span>
                    </>
                )
            },
        ],
        redemption: {
            redemptionPage: 1,
            redemptionData: [],
            redemptionCount: 0,
            redemptionSizePerPage: 10,
            redemptionLoading: false,
        },
    });

    const {
        redemptionColumns,
        referralsColumns,
        referrals,
        redemption,
    } = tableState;

    const {
        redemptionPage,
        redemptionData,
        redemptionCount,
        redemptionSizePerPage,
        redemptionLoading,
    } = redemption;

    const {
        referralsPage,
        referralsData,
        referralsCount,
        referralsSizePerPage,
        referralsLoading
    } = referrals;
    const fetchReferralsOrRedemption = async ({ page, sizePerPage, type, searchText  }) => {
        try{
            let searchQuery, response;
            //follow camelCase for type
            const camelString = `${type}`;
            searchQuery = `page=${page}&limit=${sizePerPage}`;

            if(camelString === "referrals"){
                response = (await getRedemptionsOrReferrals({
                    type: "referrals",
                    query:searchQuery
                })).data;
                setTableState(prevState => {
                    return {
                        ...prevState,
                        [camelString]: { ...prevState[camelString],
                            [`${camelString}Count`]: response.count,
                            [`${camelString}Page`]: page,
                            [`${camelString}SizePerPage`]: sizePerPage,
                            [`${camelString}Data`]: response.data.map(data => {
                               const { username, vahlid_id } = data.referred;
                               return { _id : data._id, vahlid_id, username };
                             }),
                            [`${camelString}Loading`]: false //disable loading
                        }
                    }
                });
            }

            if (camelString  === "redemption"){
                //update the product state count
                response = (await getRedemptionsOrReferrals({
                    type: "redemptions",
                    query:searchQuery,
                })).data;
                setTableState(prevState => {
                    return {
                        ...prevState,
                        [camelString]: { ...prevState[camelString],
                            [`${camelString}Count`]: response.count,
                            [`${camelString}Page`]: page,
                            [`${camelString}SizePerPage`]: sizePerPage,
                            [`${camelString}Data`]: response.data.map(data => {
                                const { status, pointsRedeemed, redemptionDate } = data;
                                return {
                                    _id: data._id,
                                    status,
                                    pointsRedeemed,
                                    redemptionDate,
                                }
                            }),
                            [`${camelString}Loading`]: false //disable loading
                        }
                    }
                });
            }
        }catch (e) {
            setLoading(false);
            handleError(e);
        }
    }

    const prepareTables = useCallback(async() => {

        await fetchReferralsOrRedemption({
            page: redemptionPage,
            sizePerPage: redemptionSizePerPage,
            type: "redemption",
        });

        await fetchReferralsOrRedemption({
            page: referralsPage,
            sizePerPage: referralsSizePerPage,
            type: "referrals",
        });

        setLoading(false);
    },[]);

    useEffect(() => {
        prepareTables();
    },[prepareTables]);

    const handleTableChange = async ({ type, page, sizePerPage, searchText, tType }) => {
        //follow camelCase for tType
        const camelString = `${tType}`;
        setTableState((prevState) => ({
                ...prevState,
                [camelString]: {
                    ...prevState[camelString],
                    [`${camelString}Loading`]: true
                }
            })
        )
        await fetchReferralsOrRedemption({
            page,
            sizePerPage,
            type: tType,
            searchText,
        });
    }


    return (
        <PreLoader
            type={"spinningBubbles"}
            color={"#005C6B"}
            loading={loading}
        >
            <div className={`card card-custom ${className} py-4`}>
                {/* Head */}
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">
                        Referral And Redemption
                      </span>
                    </h3>
                </div>
                {/* Body */}
                <div className="card-body pt-2 pb-0 mt-n3">
                    <p><strong>Redemption</strong></p>
                    <RemoteTransactionTable
                        loading={redemptionLoading}
                        columns={ redemptionColumns }
                        data={ redemptionData }
                        page={ redemptionPage }
                        sizePerPage={ redemptionSizePerPage }
                        totalSize={ redemptionCount }
                        keyField={"_id"}
                        onTableChange={(type, { page, sizePerPage, searchText }) => handleTableChange({
                            type,
                            page,
                            sizePerPage,
                            searchText,
                            tType: "redemption",
                        })}
                    />
                    <hr/>
                    <p><strong>Referral</strong></p>
                    <RemoteTransactionTable
                        loading={referralsLoading}
                        columns={ referralsColumns }
                        data={ referralsData }
                        page={ referralsPage }
                        sizePerPage={ referralsSizePerPage }
                        totalSize={ referralsCount }
                        keyField={"_id"}
                        onTableChange={(type, { page, sizePerPage, searchText }) => handleTableChange({
                            type,
                            page,
                            sizePerPage,
                            searchText,
                            tType: "referrals",
                        })}
                    />
                </div>
            </div>
        </PreLoader>
    );
}

export default connect(state => {
    const { auth } = state;
    return {
        user: auth.user,
    };
})(Referral);