import React from 'react';
import PropTypes from "prop-types";
import { Card } from 'react-bootstrap';
import _ from 'lodash';
function EmptyComponent({
                         message,
                         data,
                         children,
                         ...props
                     }){
    return (
        <>
            {
                _.isEmpty(data)
                ?
                    <Card className={'d-flex justify-content-center align-items-center w-100 h-100'}>
                        <h6>{message} :)</h6>
                    </Card>
                    :
                    children
            }
        </>
    );
}

EmptyComponent.propTypes = {
    data: PropTypes.array.isRequired,
    message: PropTypes.string.isRequired,
}
export default EmptyComponent;