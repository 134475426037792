import { actions } from "../app/modules/Auth/_redux/authRedux";
export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use((response) => {
      return response;
  },(error) => {
      if(error.response.status === 401){
          //log user out if token expires
          store.dispatch(actions.logout());
      };
      return Promise.reject(error);
  });
}
