import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import {format} from "date-fns";
import { useErrorHandler } from "react-error-boundary";

import { actions } from '../_redux/TransactionRedux';
import {
    getTransactions,
    searchTransactions
} from '../_redux/TransactionCRUD';
import { RemoteTransactionTable } from '../../Components';
import {Nav, Tab} from "react-bootstrap";
import { PreLoader } from "../../Components";

function Transaction({
                         className,
                         user,
                         ...props
}){
    const [key, setKey] = useState("Product");
    const [loading, setLoading] = useState(true);
    const handleError = useErrorHandler();
    const [tableState, setTableState ] = useState({
        columns: [
            {   
                
                dataField : 'trans_id',
                text: 'Transaction ID',
                formatter: (cell,row, rowIndex) => (
                    <>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg" style={{paddingLeft:10}}>
                          {row.trans_id}
                        </span>
                        <Link
                            className="text-muted d-block font-weight-bold text-hover-primary"
                            to={`/transaction/${row.trans_id}/status`}
                            style={{paddingLeft:10}}
                        >
                            View
                        </Link>
                    </>
                )
            },
            {
                dataField : 'customer',
                text : 'Customer',
                formatter: (cell,row, rowIndex) => {
                    return (<>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {row.customer}
                        </span>
                    </>)
                }
            },
            {
                dataField: 'created_at',
                text: 'Date',
                formatter: (cell,row,rowIndex) => (
                    <>
                        <span
                         className="text-muted d-block font-weight-bold text-hover-primary">
                            {row.created_at}
                        </span>
                        <span
                            className="text-muted d-block font-weight-bold text-hover-primary">
                            {row.time}
                        </span>
                    </>
                )
            },
            {
                dataField: 'status',
                text: 'Status',
                formatter: (cell, row, rowIndex) => (
                            row.status === "cancelled" ?
                              <span className="label label-lg label-light-danger label-inline">
                                {row.status}
                              </span>
                            :
                            <>
                                { row.status === "pending" ?
                                    <span className="label label-lg label-light-warning label-inline">
                                  {row.status}
                                 </span> :
                                    <span className="label label-lg label-light-success label-inline">
                                  {row.status}
                                 </span>
                                }
                            </>
                )
            },
            {
                dataField: 'progress',
                text : 'Action',
                formatter : (cell, row, rowIndex) => {
                    return (
                        <Link
                            to={`/transaction/${row.trans_id}/status`}
                            className="label label-lg label-light-success label-inline"
                        >View</Link>
                    )
                }
            }
        ],
        productPurchase: {
            productPurchasePage: 1,
            productPurchaseData: [],
            productPurchaseCount: 0,
            productPurchaseSizePerPage: 10,
            productPurchaseLoading: false,
        },
        productSale: {
            productSalePage: 1,
            productSaleData: [],
            productSaleCount: 0,
            productSaleSizePerPage: 10,
            productSaleLoading: false,
        },
        servicePurchase: {
            servicePurchasePage: 1,
            servicePurchaseData: [],
            servicePurchaseCount: 0,
            servicePurchaseSizePerPage: 10,
            servicePurchaseLoading: false,
        },
        serviceSale: {
            serviceSalePage: 1,
            serviceSaleData: [],
            serviceSaleCount: 0,
            serviceSaleSizePerPage: 10,
            serviceSaleLoading: false,
        },
    });

    const fetchTransactions = async ({ page, sizePerPage, type, action, searchText  }) => {
        try{
            let transactions, searchQuery;
            //separate search from pagination
            if (!_.isNil(searchText) && searchText.length > 0){
                searchQuery = type === "product" && action === "purchase"
                    ? `searchQuery=${searchText}&page=${page}&limit=${sizePerPage}`
                    : (type === "product" && action === "sale"
                        ? `searchQuery=${searchText}&page=${page}&limit=${sizePerPage}&is_seller=true`
                        : (type === "service" && action === "purchase"
                                ? `searchQuery=${searchText}&page=${page}&limit=${sizePerPage}&is_service=true`
                                : (type === "service" && action === "sale")
                                &&
                                `searchQuery=${searchText}&page=${page}&limit=${sizePerPage}&is_service=true&is_seller=true`
                        ));
                transactions = (await searchTransactions(searchQuery)).data;
            }else{
                searchQuery = type === "product" && action === "purchase"
                ? `is_service=false&buyer=${user._id}&page=${page}&limit=${sizePerPage}`
                    : (type === "product" && action === "sale" ?
                        `is_service=false&seller=${user._id}&page=${page}&limit=${sizePerPage}`
                    : (type === "service" && action === "purchase"
                            ? `is_service=true&buyer=${user._id}&page=${page}&limit=${sizePerPage}`
                            : (type === "service" && action === "sale")
                        && `is_service=true&seller=${user._id}&page=${page}&limit=${sizePerPage}`
                        )
                    );
                transactions = (await getTransactions(searchQuery)).data;
            }
            //follow camelCase for type and action
            const camelString = `${type}${_.capitalize(action)}`;
            //update the product state count
            setTableState(prevState => ({
                ...prevState,
                [camelString]: { ...prevState[camelString],
                    [`${camelString}Count`]: transactions.count,
                    [`${camelString}Page`]: page,
                    [`${camelString}SizePerPage`]: sizePerPage,
                    [`${camelString}Data`]: transactions.data.map(transaction => {
                        const { trans_id, status } = transaction;
                        return {
                            trans_id,
                            customer: action === "purchase" ? transaction?.seller?.username || "" : transaction?.buyer?.username || "",
                            created_at: format(new Date(transaction.created_at), 'MMMM dd, yyyy'),
                            status,
                            time: format(new Date(transaction.created_at), 'h:m a')
                        }
                    }),
                    [`${camelString}Loading`]: false //disable loading
                }
            }));
        }catch (e) {
            setLoading(false);
            handleError(e);
        }
    }

    const prepareTables = useCallback(async() => {
        const {
            productPurchasePage,
            productPurchaseSizePerPage,
        } = productPurchase;

        const {
            productSalePage,
            productSaleSizePerPage,
        } = productSale;

        const {
            servicePurchasePage,
            servicePurchaseSizePerPage
        } = servicePurchase;

        const {
           serviceSalePage,
           serviceSaleSizePerPage,
        } = serviceSale;

        await fetchTransactions({
            page: productPurchasePage,
            sizePerPage: productPurchaseSizePerPage,
            type: "product",
            action: "purchase"
        });
        await fetchTransactions({
            page: productSalePage,
            sizePerPage: productSaleSizePerPage,
            type: "product",
            action: "sale"
        });

        await fetchTransactions({
            page: servicePurchasePage,
            sizePerPage: servicePurchaseSizePerPage,
            type: "service",
            action: "purchase"
        });

        await fetchTransactions({
            page: serviceSalePage,
            sizePerPage: serviceSaleSizePerPage,
            type: "service",
            action: "sale"
        });
        setLoading(false);
    },[]);


    useEffect(() => {
        prepareTables();
    },[prepareTables]);

    const handleTableChange = async ({ type, page, sizePerPage, searchText, tType, action }) => {
        //follow camelCase for tType and action
        const camelString = `${tType}${_.capitalize(action)}`;
        setTableState((prevState) => ({
                ...prevState,
                [camelString]: {
                    ...prevState[camelString],
                    [`${camelString}Loading`]: true
                }
            })
        )
        await fetchTransactions({
            page,
            sizePerPage,
            type: tType,
            action,
            searchText,
        });
    }

    const {
        columns,
        productPurchase,
        productSale,
        servicePurchase,
        serviceSale,
    } = tableState;

    const {
        productPurchaseCount,
        productPurchaseSizePerPage,
        productPurchasePage,
        productPurchaseData,
        productPurchaseLoading,
    } = productPurchase;

    const {
        productSaleCount,
        productSaleSizePerPage,
        productSaleData,
        productSalePage,
        productSaleLoading,
    } = productSale;

    const {
        servicePurchaseCount,
        servicePurchaseSizePerPage,
        servicePurchaseData,
        servicePurchasePage,
        servicePurchaseLoading,
    } = servicePurchase;

    const {
        serviceSaleCount,
        serviceSaleSizePerPage,
        serviceSaleData,
        serviceSalePage,
        serviceSaleLoading,
    } = serviceSale;

    return (
        <PreLoader
            type={"spinningBubbles"}
            color={"#005C6B"}
            loading={loading}
        >
            <div className={`card card-custom ${className} py-4`}>
                {/* Head */}
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">
                        Transactions
                      </span>
                    </h3>
                    <div className="card-toolbar">
                        <Tab.Container defaultActiveKey={key}>
                            <Nav
                                as="ul"
                                onSelect={(_key) => setKey(_key)}
                                className="nav nav-pills nav-pills-sm nav-dark-75"
                            >
                                <Nav.Item className="nav-item" as="li">
                                    <Nav.Link
                                        eventKey="Product"
                                        className={`nav-link py-2 px-4 ${
                                            key === "Product" ? "active" : ""
                                        }`}
                                    >
                                        Product
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item" as="li">
                                    <Nav.Link
                                        eventKey="Service"
                                        className={`nav-link py-2 px-4 ${
                                            key === "Service" ? "active" : ""
                                        }`}
                                    >
                                        Service
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Tab.Container>
                    </div>
                </div>
                {/* Body */}
                <div className="card-body pt-2 pb-0 mt-n3">
                    <div className={`tab-content mt-5`} id="myTabTables12">
                        {/* begin::Tap pane Product */}
                        <div
                            className={`tab-pane fade ${key === "Product" ? "show active" : ""}`}
                        >
                            <p><strong>Purchase</strong></p>
                            <RemoteTransactionTable
                                loading={productPurchaseLoading}
                                columns={ columns }
                                data={ productPurchaseData }
                                page={ productPurchasePage }
                                sizePerPage={ productPurchaseSizePerPage }
                                totalSize={ productPurchaseCount }
                                keyField={"trans_id"}
                                onTableChange={(type, { page, sizePerPage, searchText }) => handleTableChange({
                                    type,
                                    page,
                                    sizePerPage,
                                    searchText,
                                    tType: "product",
                                    action: "purchase",
                                })}
                            />
                            <hr/>
                            <p><strong>Sales</strong></p>
                            <RemoteTransactionTable
                                loading={productSaleLoading}
                                columns={ columns }
                                data={ productSaleData }
                                page={ productSalePage }
                                sizePerPage={ productSaleSizePerPage }
                                totalSize={ productSaleCount }
                                keyField={"trans_id"}
                                onTableChange={(type, { page, sizePerPage, searchText }) => handleTableChange({
                                    type,
                                    page,
                                    sizePerPage,
                                    searchText,
                                    tType: "product",
                                    action: "sale",
                                })}
                            />
                        </div>
                        {/* end::Tap pane Product */}
                        {/* begin::Tap pane Service */}
                        <div
                            className={`tab-pane fade ${key === "Service" ? "show active" : ""}`}
                        >
                            <p><strong>Purchase</strong></p>
                            <RemoteTransactionTable
                                loading={servicePurchaseLoading}
                                columns={ columns }
                                data={ servicePurchaseData }
                                page={ servicePurchasePage }
                                sizePerPage={ servicePurchaseSizePerPage }
                                totalSize={ servicePurchaseCount }
                                keyField={"trans_id"}
                                onTableChange={(type, { page, sizePerPage, searchText }) => handleTableChange({
                                    type,
                                    page,
                                    sizePerPage,
                                    searchText,
                                    tType: "service",
                                    action: "purchase",
                                })}
                            />
                            <hr/>
                            <p><strong>Sales</strong></p>
                            <RemoteTransactionTable
                                loading={serviceSaleLoading}
                                columns={ columns }
                                data={ serviceSaleData }
                                page={ serviceSalePage }
                                sizePerPage={ serviceSaleSizePerPage }
                                totalSize={ serviceSaleCount }
                                keyField={"trans_id"}
                                onTableChange={(type, { page, sizePerPage, searchText }) => handleTableChange({
                                    type,
                                    page,
                                    sizePerPage,
                                    searchText,
                                    tType: "service",
                                    action: "sale",
                                })}
                            />
                        </div>
                        {/* end::Tap pane Service */}
                    </div>
                </div>
            </div>
        </PreLoader>
    );
}

const mapStateToProps = state => {
    const { auth } = state;
    return {
        user: auth.user,
    };
}

export default connect(mapStateToProps, actions)(Transaction);