import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

function Button({ loading,
                    type,
                    text,
                    className,
                    ...props }){
    return (<>
        <button
            type={!_.isEmpty(type) ? type : "submit"}
            className={!_.isEmpty(className) ? className : "btn btn-primary"}
            disabled={loading}
            {...props}
        >
            {!loading && (_.isEmpty(text) ? "Save Changes" : text)}
            {loading && (
                <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                >
                  Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                </span>
            )}
        </button>
    </>)
}

Button.propTypes = {
    loading: PropTypes.bool.isRequired,
    type: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    // onClick: PropTypes.func
}

export default Button;