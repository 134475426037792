import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';



export const actionTypes = {
    TransactionCreated: "[TransactionCreated] Action",
    FetchTotalTransactionSuccessFul: "[FetchTotalTransactionSuccessFul] Action",
    FetchChartDataSuccessFul: "[FetchChartDataSuccessFul] Action",
    FetchRecentSaleTransactionSuccessFull: "[FetchRecentSaleTransactionSuccessFull] Action",
    FetchRecentPurchaseTransactionSuccessFull: "[FetchRecentPurchaseTransactionSuccessFull] Action",
    FetchRecentCompletedTransactionSuccessFull: "[FetchRecentCompletedTransactionSuccessFull] Action",
    FetchRecentPendingTransactionSuccessFull: "[FetchRecentPendingTransactionSuccessFull] Action",
    FetchRecentCancelledTransactionSuccessFull: "[FetchRecentCancelledTransactionSuccessFull] Action",
    FetchTransactionSuccessFull: "[FetchTransactionSuccessFull] Action",
    UpdateTransactionSuccessFull: "[UpdateTransactionSuccessFull] Action",
}

const initialTransactionState = {
    chart: [],
    totalTransaction: {
        totalSales: 0,
        totalPurchases: 0,
        count: 0,
    },
    transaction : {
        delivery_addr : null,
        created_at : null,
        trans_id : null,
        products : [],
        seller : {
            _id : null
        },
        buyer : {
           _id : null
        },
        pay_deadline : null,
        is_deposit_made : false,
        products_price : null
    },
    recent_sale_transactions : [],
    recent_purchase_transactions : [],
    recent_completed_transactions : [],
    recent_pending_transactions: [],
    recent_cancelled_transactions: [],
}


export const transactionReducer = persistReducer({
    key : 'vahlid-service',
    storage
},(state = initialTransactionState, action) => {
    switch (action.type){
        case actionTypes.TransactionCreated: {
            return {
                ...state,
                transaction : action.payload,
                transactions : []
            }
        }
        case actionTypes.FetchTotalTransactionSuccessFul: {
            return {
                ...state,
                totalTransaction: {
                    ...state.totalTransaction,
                    ...action.payload
                }
            }
        }
        case actionTypes.FetchChartDataSuccessFul: {
            return {
                ...state,
                chart: action.payload
            }
        }
        case actionTypes.FetchRecentSaleTransactionSuccessFull: {
            return {
                ...state,
                recent_sale_transactions : action.payload,
            }
        }
        case actionTypes.FetchRecentPurchaseTransactionSuccessFull: {
            return {
                ...state,
                recent_purchase_transactions : action.payload,
            }
        }
        case actionTypes.FetchRecentCompletedTransactionSuccessFull: {
            return {
                ...state,
                recent_completed_transactions : action.payload,
            }
        }
        case actionTypes.FetchRecentPendingTransactionSuccessFull: {
            return {
                ...state,
                recent_pending_transactions: action.payload,
            }
        }
        case actionTypes.FetchRecentCancelledTransactionSuccessFull: {
            return {
                ...state,
                recent_cancelled_transactions: action.payload,
            }
        }
        case actionTypes.FetchTransactionSuccessFull : {
            return {
                ...state,
                transaction : action.payload,
                transactions : []
            }
        }
        case actionTypes.UpdateTransactionSuccessFull : {
            return {
                ...state,
                transaction : action.payload,
                transactions: []
            }
        }
        default:
            return state
    }
});


export const actions = {
    FetchTotalTransactionSuccessFulAction: (transaction) => ({ type: actionTypes.FetchTotalTransactionSuccessFul, payload: transaction }),
    TransactionCreatedAction : (transaction) => ({ type : actionTypes.TransactionCreated, payload : transaction}),
    FetchChartDataSuccessFulAction: (transaction) => ({ type: actionTypes.FetchChartDataSuccessFul, payload: transaction}),
    FetchTransactionSuccessFull: (transaction) => ({ type : actionTypes.FetchTransactionSuccessFull, payload : transaction}),
    FetchRecentSaleTransactionSuccessFullAction: (transaction) => ({ type: actionTypes.FetchRecentSaleTransactionSuccessFull, payload: transaction}),
    FetchRecentPurchaseTransactionSuccessFull: (transaction) => ({ type: actionTypes.FetchRecentPurchaseTransactionSuccessFull, payload: transaction}),
    FetchRecentCompletedTransactionSuccessFull: (transaction) => ({ type: actionTypes.FetchRecentCompletedTransactionSuccessFull, payload: transaction}),
    FetchRecentPendingTransactionSuccessFull: (transaction) => ({ type: actionTypes.FetchRecentPendingTransactionSuccessFull, payload: transaction}),
    FetchRecentCancelledTransactionSuccessFull: (transaction) => ({ type: actionTypes.FetchRecentCancelledTransactionSuccessFull, payload: transaction}),
    // FetchAllTransactionSuccessFullAction: (transactions) => ({type : actionTypes.FetchAllTransactionSuccessFull, payload : transactions }),
    UpdateTransactionSuccessFull: (transaction) => ({type: actionTypes.UpdateTransactionSuccessFull, payload: transaction}),
}