import axios from 'axios';

const {
  REACT_APP_API_USERS_URL,
  REACT_APP_VAHLID_API_URL,
  REACT_APP_API_URL
} = process.env;

const USER_UPDATE_URL = `${REACT_APP_API_USERS_URL}/update`;
const FETCH_BANKS_URL = `${REACT_APP_VAHLID_API_URL}/vendor/banks`;
const UPDATE_ACCOUNT_URL = `${REACT_APP_VAHLID_API_URL}/users/accounts`;
const SIGN_PROFILE_IMAGE_UPLOAD_URL = `${REACT_APP_API_URL}/ping/sign-cloudinary-profile-image-upload`;
const RESET_PASSWORD_URL = `${REACT_APP_VAHLID_API_URL}/users/password`;

export const updateUser = (updateObj) => { 
  return axios.patch(USER_UPDATE_URL, updateObj);
}

export const generateReferralCode = () => {
  return axios.get(`${REACT_APP_API_USERS_URL}/referrals/code`);
}

export const getBanks = async () => {
  const response = await axios.get(FETCH_BANKS_URL);
  const { data: responseData } = response;
  const { data } = responseData;
  const { banks } = data;

  return banks;
}

export const updateAccount = async (updateObj) => {
  const response = await axios.put(UPDATE_ACCOUNT_URL, updateObj);
  return response.data;
}

export const getCloudinaryProfileUploadSignature = () => {
  return axios.get(SIGN_PROFILE_IMAGE_UPLOAD_URL);
}

export async function cloudinaryProfileImageUpload(url,photo, config){
  try {
    const {
      signature,
      apikey,
      timestamp,
    } = config;
    //create a new axios instance for this request
    //this is required because the default authorization header in
    //the react axios interceptor is intercepting call request
    // external API
    const instance = axios.create();
    const formData = new FormData();
    formData.append('file',photo);
    formData.append("api_key", apikey);
    formData.append("timestamp", timestamp);
    formData.append("signature", signature);
    formData.append("eager", "c_pad,h_100,w_100|c_crop,h_400,w_400");
    formData.append("folder", "vahlid_mern/avatar");
    delete instance.defaults.headers.common["authorization"];
    return (await instance.post(url,formData)).data.secure_url;
  }catch (e) {return Promise.reject(e);}
}

export const resetPassword = async function (passwordResetData) {
  await axios.patch(RESET_PASSWORD_URL, passwordResetData);
  return true;
}


export const redeemReferralPoints = (points) => {
  return axios.patch(`${REACT_APP_API_USERS_URL}/redemptions`, { points });
}