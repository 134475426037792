import React, {useState,useEffect,useCallback} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from "react-error-boundary";

//importing components
import {
    LockDisputeChat,
    PreLoader,
} from "../../Components";

import {
    ChatBody,
    ChatFooter,
} from "../component";
import {
    getDisputeByDisputeID,
    getMessagesForDispute,
    getTransactionByTransID
} from "../_redux/DisputeCRUD";

//importing actions
import { actions } from "../_redux/DisputeRedux";
import { actions as transActions } from "../../Transaction/_redux/TransactionRedux";

//importing styles
import "./Chat.css";

function DisputeSession({
                            user,
                            dispute,
                            transaction,
                            FetchDisputeSuccessfulAction,
                            FetchDisputeMessagesSuccessfulAction,
                            FetchTransactionSuccessFull,
                            SetMessageTotalPagesSuccessFulAction,
                            ClearDisputeMessagesSuccessfulAction,
                            FetchMoreDisputeMessagesSuccessfulAction,
                            ...props
}){
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const handleError = useErrorHandler();
    const { trans_id, dispute_id } = useParams();

    const limit = 10;

    const fetchDisputeDetails = useCallback( () => {
        //also reset the current page to the initial value
        setCurrentPage(1);
        getDisputeByDisputeID(dispute_id).then(async res => {
            //dispatch dispute to store
            FetchDisputeSuccessfulAction(res.data.data);
            //also get messages for the dispute
            const { data, count } = (await getMessagesForDispute(res.data.data._id,`page=${currentPage}&limit=${limit}`)).data;
            //update the total number of pages on
            //successful fetch of messages
            const messageTotalPages = Math.round(count / limit);
            SetMessageTotalPagesSuccessFulAction({
                count,
                messageTotalPages,
            });
            FetchDisputeMessagesSuccessfulAction(data);
            //disable loading
            setLoading(false);
        },handleError);
    },[
        getDisputeByDisputeID,
        getMessagesForDispute,
        SetMessageTotalPagesSuccessFulAction,
        FetchDisputeMessagesSuccessfulAction
    ]);

    const fetchTransactionByTransID = useCallback(() => {
        getTransactionByTransID(trans_id).then(response => {
            //dispatch transaction to the store
            FetchTransactionSuccessFull(response.data.data);
        },handleError);
    },[
        getTransactionByTransID,
        FetchTransactionSuccessFull
    ]);

    const fetchMoreMessagesForDisputes = () => {
        if (currentPage <= dispute.messageTotalPages){
            const nextPage = currentPage + 1;
            //always get the next 10 records
            getMessagesForDispute(dispute._id,`page=${nextPage}&limit=${limit}`).then(
                response => {
                    const { data, count } = response.data;
                    FetchMoreDisputeMessagesSuccessfulAction(data);
                    //also update the new count of messages,
                    SetMessageTotalPagesSuccessFulAction({
                        count,
                        messageTotalPages: Math.round(count / limit),
                    });
                    //update the page for the next scroll
                    setCurrentPage(nextPage);
                },
                handleError
            );
        }
    };

    useEffect(() => {
        fetchTransactionByTransID();
        fetchDisputeDetails();
    },[
        fetchDisputeDetails,
        fetchTransactionByTransID
    ]);

    return (
        <PreLoader
            loading={loading}
            type={'spinningBubbles'}
            color={'#005C6B'}
        >
            <LockDisputeChat
                user={user}
                dispute={dispute}
                transaction={transaction}
                FetchDisputeSuccessfulAction={FetchDisputeSuccessfulAction}
            >
                <div className={"chat"} style={{height: "100%", margin:0, overflow:"hidden"}}>
                    <div className={"chat__body"}>
                        <ChatBody
                            messages={dispute.messages}
                            user={user}
                            fetchMoreMessagesForDisputes={fetchMoreMessagesForDisputes}
                        />
                    </div>
                    <div style={{paddingRight: "5px"}}>
                        <ChatFooter
                            trans_id={trans_id}
                            dispute_id={dispute_id}
                            totalPages={dispute.messageTotalPages}
                            fetchDisputeDetails={fetchDisputeDetails}
                            fetchTransactionByTransID={fetchTransactionByTransID}
                        />
                    </div>
                </div>
            </LockDisputeChat>
        </PreLoader>
    );
}

// import { ClassErrorBoundary } from '../../Components';
// import { Chat } from '../component';
// function DisputeSession(props){
//   const { trans_id, dispute_id } = useParams();
//   return(
//       <ClassErrorBoundary>
//           <Chat
//               trans_id={trans_id}
//               dispute_id={dispute_id}
//           />
//       </ClassErrorBoundary>
//   );
//
// }

const mapStateToProps = state => {
    const { auth, disputeStore, transactionStore } = state;
    return {
        user: auth.user,
        dispute: disputeStore.dispute,
        transaction: transactionStore.transaction,
    }
}
export default connect(mapStateToProps, {
    ...actions,
    ...transActions,
})(DisputeSession);

