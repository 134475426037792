import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Card} from "react-bootstrap";

import Button from "./Button";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {refundBuyerOrSeller} from "../Dispute/_redux/DisputeCRUD";

function LockDisputeChat({
                             user,
                             transaction,
                             dispute,
                             children,
                             FetchDisputeSuccessfulAction,
                             ...props
}){
    const [refundLoading, setRefundLoading] = useState(false);
    const [disburseLoading, setDisburseLoading] = useState(false);

    const {
        title,
        status,
        description
    }= dispute;

    const refunders = ["superadmin"];


    const  handleSellerDisburseMent = async (event) => {
        event.preventDefault();
        try {
            const MySwal = withReactContent(Swal)
            const { value } = await MySwal.fire({
                title: <p>Are you sure?</p>,
                icon: 'question',
                text: 'Are you sure?, You are about to transfer money to the seller.',
                showCancelButton: true,
            });

            if (value){
                //initialize loader button over here...
                setDisburseLoading(true);
                const { data } = (await refundBuyerOrSeller({
                    _id: transaction._id,
                    dispute_id: dispute._id,
                    position: "seller",
                })).data;
                setDisburseLoading(false);
                FetchDisputeSuccessfulAction(data)
            }

        }catch (e) {
            //stop loader button over here
            setDisburseLoading(false);
        }

    }


    const  handleBuyerRefund = async (event) => {
        event.preventDefault();
        try {
            const MySwal = withReactContent(Swal)
            const { value } = await MySwal.fire({
                title: <p>Are you sure?</p>,
                icon: 'question',
                text: 'Are you sure?, You are about to return money to the buyer.',
                showCancelButton: true,
            });

            if (value){
                //initialize loader button over here...
                setRefundLoading(true);
                const { data } = (await refundBuyerOrSeller({
                    _id: transaction._id,
                    dispute_id: dispute._id,
                    position: "buyer",
                })).data;
                setRefundLoading(false);
                FetchDisputeSuccessfulAction(data)
            }

        }catch (e) {
            //stop loader button over here
            setRefundLoading(false);
        }

    }


    return(<>
        { status === "resolved" || status === "refunded"
            ?
            <Card className={'d-flex justify-content-center align-items-center w-100 h-100'}>
                <Card.Body>
                    <Card.Title>Title: {title}</Card.Title>
                    <Card.Text><strong>Description:</strong> {description}</Card.Text>
                    <Card.Text><strong>Reported By:</strong> {dispute.reported_by.username}</Card.Text>
                    <Card.Text><strong>Reported User:</strong>{dispute.reported_user.username}</Card.Text>
                    <Card.Text><strong>Assigned Agent:</strong> Coming Soon</Card.Text>
                    <Card.Text><strong>Status:</strong> {status}</Card.Text>
                    {refunders.includes(user.role) &&
                        <>
                        {
                            status !== "refunded" ?
                                <>
                                    <Button
                                        loading={refundLoading}
                                        type={"button"}
                                        text={"Refund Buyer"}
                                        className={"btn btn-primary mr-2 mt-2"}
                                        onClick={handleBuyerRefund}
                                    />
                                    <Button
                                        loading={disburseLoading}
                                        type={"button"}
                                        text={"Disburse to Seller"}
                                        className={"btn btn-primary mt-2"}
                                        onClick={handleSellerDisburseMent}
                                    />
                                </>
                            :
                                ""
                        }
                        </>
                    }
                </Card.Body>
            </Card>
            :
            children
        }
    </>);
}

LockDisputeChat.propTypes = {
    user: PropTypes.object.isRequired,
    transaction: PropTypes.object.isRequired,
    dispute: PropTypes.object.isRequired,
    FetchDisputeSuccessfulAction: PropTypes.func.isRequired,
}
export default LockDisputeChat;