import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from "react-router-dom";

import {
    cancelTransaction,
    IveDelivered,
    confirmDelivery,
} from "../Transaction/_redux/TransactionCRUD";
import { connect } from "react-redux";
import { actions } from "../Transaction/_redux/TransactionRedux";
import { Button } from "../Components";

const is_expired_transaction = (pay_deadline) => {
    const target = new Date(pay_deadline);
    const current = (new Date()).getTime()  - 86400000;
    return (target.getTime() - current)  >= 0;
}

export default connect(null,actions)(function FeedbackDetails({
                                                                 _id,
                                                                 status,
                                                                 is_delivery_made,
                                                                 is_deposit_made,
                                                                 seller,
                                                                 products_price,
                                                                 pay_deadline,
                                                                 trans_id,
                                                                 is_delivery_confirmed,
                                                                 UpdateTransactionSuccessFull,
                                                                 ...props
}){
    const [loading, setLoading] = useState(false);
    const is_seller = seller._id === _id;
    const history = useHistory();

    const handleCancelTransaction = async (trans_id) => {
        try {
            setLoading(true);
            let response = await cancelTransaction(trans_id);
            const { data } = response.data;
            //update the state
            UpdateTransactionSuccessFull(data);
            setLoading(false);
        }catch (e){
            //disable loading
            setLoading(false);
            //   probably flash some error messages
            console.log(e);
        }
    }

    const handleIveDelivered = async (trans_id) => {
        try {
            setLoading(true);
            let response = await IveDelivered(trans_id);
            const { data } = response.data;
            //update the state
            UpdateTransactionSuccessFull(data);
            setLoading(false);
        }catch (e) {
            setLoading(false);
            console.log(e.message);
        }
    }


    const handleReportSeller = async (trans_id) => {
        try {
            const MySwal = withReactContent(Swal)
            const { value } = await MySwal.fire({
                title: <p>Are you sure?</p>,
                icon: 'question',
                text: 'We spend time to review disputes, please be sure before you raise one. To prevent your account from being disabled.',
                showCancelButton: true,
            });
            if (value){
                history.push(`/dispute/${trans_id}/raise`)
            }
        }catch (e) {
            //flash some notifs
            console.log(e.message);
        }
    }

    const handleConfirmDelivery = async (trans_id) => {
        try {
            const MySwal = withReactContent(Swal)
            const { value } = await MySwal.fire({
                title: <p>Are you sure?</p>,
                icon: 'question',
                text: 'Money would automatically be disbursed to the seller',
                showCancelButton: true,
            });
            //proceed
            if(value){
                setLoading(true);
                const response = await confirmDelivery(trans_id);
                const { data } = response.data;
                UpdateTransactionSuccessFull(data);
                setLoading(false);
            }

        }catch (e) {
            setLoading(false);
            console.log(e.message);
        }
    }


    if (status === "pending"){
        if (is_seller){
            //Indicates the currently authenticated user is a seller
            if (is_deposit_made) {
                if (is_delivery_made) {
                    return <>
                        <p className="card-text">The customer has been notified about the delivery and would confirm
                            receipt shortly or open a dispute resolution if any issues were detected with the
                            product.<br/><br/>Learn more about our <a href="https://vahlid.com/dispute-resolution/">dispute
                                resolution process</a>. </p>
                    </>
                } else {
                    return <>
                        <p className="card-text" id="prod-delivery">The customer has cleared the pending payment and is
                            now awaiting product delivery.</p>
                        <Button type="button"
                                id="delivered-btn"
                                className={"btn btn-primary mr-2"}
                                onClick={() => handleIveDelivered(trans_id)}
                                loading={loading}
                                text={"I've Delivered"}
                                style={{backgroundColor: '#005C6B', color: '#FFFFFF'}}
                        />

                    </>
                }
            } else {
                return <>
                    <p className="card-text">The customer is yet to make payment for this product.</p>
                    <Button
                        type={"button"}
                        loading={loading}
                        className="btn btn-warning"
                        text={"Cancel Transaction"}
                        onClick={() => handleCancelTransaction(trans_id)} />
                </>
            }
        }else{
            //Indicates the currently authenticated user is a buyer
            if (is_deposit_made){
                if (is_delivery_made){

                    if (is_delivery_confirmed){
                        return (<>
                            <p>We would notify you via mail, on successful disbursement of funds to the seller</p>
                            <Link to={`/transaction/${trans_id}/invoice`} className={"mr-2 btn btn-warning"}>Invoice</Link>
                        </>);
                    }

                    return (<>
                        <p>
                            The seller claims to have rendered the required service
                        </p>
                        <Link to={`/transaction/${trans_id}/invoice`} className={"mr-2 btn btn-warning"}>Invoice</Link>
                        <Button type="button"
                                className={"btn btn-primary mr-2"}
                                onClick={() => handleConfirmDelivery(trans_id)}
                                loading={loading}
                                text={"Confirm Delivery"}
                        />
                        <Button
                            type={'button'}
                            loading={false}
                            text={"Report Seller"}
                            onClick={() => handleReportSeller(trans_id)}
                        />
                    </>);
                }else{
                    return (<>
                        <p>The service is in the delivery phase, you would be contacted by the seller</p>
                        <Link to={`/transaction/${trans_id}/invoice`} className={"mr-2 btn btn-warning"}>Invoice</Link>
                        <Button
                            type={'button'}
                            loading={false}
                            text={"Report Seller"}
                            onClick={() => handleReportSeller(trans_id)}
                        />
                    </>);
                }
            }else{
                if(is_expired_transaction(pay_deadline)){
                    return (<>
                        <p>
                            You have a pending payment of &#8358;{products_price}, Please view the invoice to make your payment.
                        </p>
                        <Link to={`/transaction/${trans_id}/invoice`} className={"mr-2 btn btn-warning"}>Invoice</Link>
                        <Button
                            type={'button'}
                            loading={false}
                            text={"Report Seller"}
                            onClick={() => handleReportSeller(trans_id)} />
                    </>);
                }else{
                    return (<>
                        <p>You can no longer make payment for this product because the payment deadline has expired.</p>
                    </>);
                }
            }
        }

    }else if(status === "cancelled"){
        return (<>
            <p>
                This transaction has been cancelled
            </p>
        </>);
    }else if(status === "completed"){
        return (<>
            <p>
                Transaction has been completed
            </p>
        </>);
    }

    return (<p>Oops, something went wrong :)</p>)
}
);