import axios from 'axios';

const {
    REACT_APP_URL,
    REACT_APP_API_URL,
    REACT_APP_API_TRANSACTION_URL,
    REACT_APP_API_VENDOR_URL,
} = process.env;

export const SIGN_CLOUDINARY_PRODUCT_IMAGE_UPLOAD_URL = `${REACT_APP_API_URL}/ping/sign-cloudinary-product-image-upload`;

export const PRODUCT_PURCHASE_TRANSACTION_URL = `${REACT_APP_API_TRANSACTION_URL}/product/purchase`;
export const PRODUCT_SALE_TRANSACTION_URL = `${REACT_APP_API_TRANSACTION_URL}/product/sale`;
export const SERVICE_PURCHASE_TRANSACTION_URL = `${REACT_APP_API_TRANSACTION_URL}/service/purchase`;
export const SERVICE_SALE_TRANSACTION_URL = `${REACT_APP_API_TRANSACTION_URL}/service/sale`;
export const INITIALIZE_FLUTTERWAVE_PAYMENT_URL = `${REACT_APP_API_TRANSACTION_URL}/initialize`;
export const CANCEL_TRANSACTION_URL = (trans_id) => `${REACT_APP_API_TRANSACTION_URL}/cancel/${trans_id}`;
//declare the vahlid callback url to redirect to after vahlid
//verifies transaction

export const getCallbackUrl = (trans_id) => `${REACT_APP_URL}/transaction/${trans_id}/status`;


//recent transactions i.e sale/purchase/completed/pending/cancelled
export const getRecentTransaction = (params) =>{
   return axios.get(`${REACT_APP_API_TRANSACTION_URL}/${params}`);
}


//get chart summary
export const getChartData = (query) => {
    return axios.get(`${REACT_APP_API_TRANSACTION_URL}/month-summary?${query}`);
}

//get total transaction
export const getTotalTransaction = (route) => {
    return axios.get(`${REACT_APP_API_TRANSACTION_URL}/${route}`);
}

//ask the boss for permission
export function getCloudinaryProductImageSignature(){
    return axios.get(SIGN_CLOUDINARY_PRODUCT_IMAGE_UPLOAD_URL);
}

//Update delivery status for the transaction
export const IveDelivered = (trans_id) => {
    return axios.post(`${REACT_APP_API_TRANSACTION_URL}/ive-delivered`,{
        trans_id
    })
}

//handles automatic disbursement of funds
//at confirm delivery button
export const confirmDelivery = (trans_id) => {
    return axios.post(`${REACT_APP_API_TRANSACTION_URL}/confirm-delivery`,{
        trans_id
    })
}

//perform cloudinary parallel image upload to
//to cloudinary and transforms the prod_image field
export function cloudinaryMultipleImageUpload(url,products, config){
    const {
        signature,
        apikey,
        timestamp,
    } = config;
    //create a new axios instance for this request
    //this is required because the default authorization header in
    //the react axios interceptor is intercepting call request
    // external API
    const instance = axios.create();
    const parallelUploadRequest = products.map((product, index) => {
        const formData = new FormData();
        formData.append('file',product.prod_image);
        formData.append("api_key", apikey);
        formData.append("timestamp", timestamp);
        formData.append("signature", signature);
        formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
        formData.append("folder", "vahlid_mern/transactions");
        delete instance.defaults.headers.common["authorization"];
        return instance.post(url,formData);
    });

    return Promise.all(parallelUploadRequest).then(response => {
        return products.map((product, index) => {
            product.prod_image = response[index].data.secure_url;
            return product;
        });
    });
}

export function createServicePurchaseTransaction(data){
    return axios.post(SERVICE_PURCHASE_TRANSACTION_URL,data);
}

export function createServiceSaleTransaction(data){
    return axios.post(SERVICE_SALE_TRANSACTION_URL,data);
}

export function createProductPurchaseTransaction(data){
    return axios.post(PRODUCT_PURCHASE_TRANSACTION_URL,data);
}

export function createProductSaleTransaction(data){
    return axios.post(PRODUCT_SALE_TRANSACTION_URL,data);
}



export async function cancelTransaction(trans_id){
   return axios.get(CANCEL_TRANSACTION_URL(trans_id));
}

export function processFlutterwavePayment(data,type){
    const callbackUrl = getCallbackUrl(data.trans_id);
    return axios.post(INITIALIZE_FLUTTERWAVE_PAYMENT_URL,{type, callbackUrl,...data});
}

export const getTransactionById = ({ trans_id, source}) => {
     return axios.get(`${REACT_APP_API_TRANSACTION_URL}/${trans_id}`,{
         cancelToken : source.token
     })
}


export const getTransactions = (query) => {
    return axios.get(`${REACT_APP_API_TRANSACTION_URL}?${query}`);
}

export const searchTransactions = (query) => {
    return axios.get(`${REACT_APP_API_TRANSACTION_URL}/search?${query}`);
}

export const verifyPromoCode = (promoCode) => {
    return axios.get(`${REACT_APP_API_VENDOR_URL}/promo/verify?promoCode=${promoCode}`);
}