import React, { useState } from "react";
import { connect } from "react-redux";
import {format} from "date-fns";
import InfiniteScroll from 'react-infinite-scroll-component';
//importing material-ui-icon
import AlarmIcon from "@material-ui/icons/Alarm";
import DoneIcon from "@material-ui/icons/Done";
import DialogCustom from "./DialogCustom";
//importing styles
import "./ChatBody.css";

function ChatBody({
                      messages,
                      user,
                      count,
                      fetchMoreMessagesForDisputes,
                  }) {
    const [currentDialogPhoto, setCurrentDialogPhoto] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const handleDialogOpen = (photo) => {
        setCurrentDialogPhoto(photo);
        setShowDialog(true);
    }

    const handleDialogClose = () => {
        setShowDialog(false);
    }

    return (
        <InfiniteScroll
            dataLength={messages.length} //This is important field to render the next data
            next={fetchMoreMessagesForDisputes}
            hasMore={messages.length < count}
            height={400}
            loader={<h6 style={{ textAlign: 'center' }}>Loading...</h6>}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            inverse={true}
            // endMessage={
            //     <p style={{ textAlign: 'center' }}>
            //         <b>Nothing to see here</b>
            //     </p>
            // }
            initialScrollY={400}
        >
            {messages.map((message, index) => (
                <div
                    key={index}
                    className={`chat__message 
                ${message.author === user._id && "chat__sender"} 
                ${message.photo && "chat__message_media_image"}
                `}
                >
              <span
                  className={`chat__name ${
                      message.author === user._id && "chat__name_sender"
                  }`}
              >
                {message.senderName}
              </span>
                    <div className="chat__body_image_container">
                        {message.photo ? (
                            <>
                                <img
                                    alt="chat image"
                                    className="chat__body_image"
                                    src={message.photo}
                                    onClick={(e) => handleDialogOpen(message.photo)}
                                />

                                <DialogCustom
                                    open={showDialog}
                                    close={handleDialogClose}
                                    photo={currentDialogPhoto}
                                    user={user}
                                />
                            </>
                        ) : null}
                    </div>


                    <div className="chat__message_box">
                        <div
                            className={`chat__message_box_text ${
                                message.author === user._id && "chat__message_box_text_sender"
                            }
                           ${
                                message.photo &&
                                !message.caption &&
                                "chat__message_box_text_no_caption"
                            } `}
                        >
                            {message.message ? message.message : null}
                            {message.caption ? message.caption : null}
                            {message.url ? (
                                <a
                                    target="_blank"
                                    href={`${message.url}`}
                                    rel="noopener noreferrer"
                                >
                                    {message.url}
                                </a>
                            ) : null}

                            <div
                                className={`chat__timestamp_container ${
                                    message.author === user._id && "chat__timestamp_container_sender"
                                }`}
                            >
                                {message.created_at ? (
                                    <div
                                        className={`chat__timestamp 
                                            ${
                                            message.photo &&
                                            !message.caption &&
                                            "chat__timestamp_media_photo"
                                        }  
                                       `}
                                    >
                                <span>
                                    {format(new Date(message.created_at), 'h:m a')}
                                    {message.author === user._id ? <DoneIcon /> : null}
                                </span>
                                    </div>
                                ) : (
                                    <div className="chat__timestamp">
                                        <span>
                                            {format(new Date(), 'h:m a')}
                                            {message.author === user._id ? <AlarmIcon /> : null}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </InfiniteScroll>
    );
}


export default connect((state) => {
    const { count } = state.disputeStore.dispute;
    return { count };
})(ChatBody);
