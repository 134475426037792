import React from "react";
import BankAccountDetails from "../cards/BankAccountDetails";
import { default as ProfileDetails } from "../cards/ProfileDetails";
import { default as SignInMethod } from "../cards/SignInMethod";
import ProfileImage from "../cards/ProfileImage";
import Referral from "../cards/Referral";

export function Settings() {
  return (
    <>
      <ProfileImage />
      <ProfileDetails />
      <SignInMethod />
      <BankAccountDetails />
      <Referral />
    </>
  );
}
