import React, {useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {FormattedMessage, injectIntl} from "react-intl";
import { connect } from 'react-redux';
import {useFormik} from "formik";
import { resetPassword } from "../_redux/authCrud";
import * as Yup from "yup";


function ResetPassword(props){
    const { intl, history } = props;
    const {email, hash} = useParams();
    const [loading, setLoading] = useState(false);
    const ResetPasswordSchema = Yup.object().shape({
        new_password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        confirm_password: Yup.string()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
            .when("new_password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("new_password")],
                    "Password and Confirm Password didn't match"
                ),
            }),
    });

    const initialValues = {
        new_password : "",
        confirm_password: "",
    }

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };


    const formik = useFormik({
        initialValues,
        validationSchema: ResetPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            resetPassword({email, hash, new_password: values.new_password}).then((res) => {
               history.push('/');
            }).catch(err => {
                //flash message if something went wrong...
               history.push(history.location.pathname);
                disableLoading();
            });
        },
    });


    return(<>
        <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
        >
            <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "new_password"
                    )}`}
                    name="new_password"
                    {...formik.getFieldProps("new_password")}
                />
                {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.new_password}</div>
                    </div>
                ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Confirm Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "confirm_password"
                    )}`}
                    name="confirm_password"
                    {...formik.getFieldProps("confirm_password")}
                />
                {formik.touched.confirm_password && formik.errors.confirm_password ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.confirm_password}</div>
                    </div>
                ) : null}
            </div>

            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                >
                    <span>Sign In</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
            </div>
        </form>
        {/*end::Form*/}

    </>);
}

export default injectIntl(connect(null,null)(ResetPassword));
