import React, { Component } from "react";
import { Field, ErrorMessage } from "formik";

class ProductField extends Component{

    constructor(props) {
        super(props);
        this.fileUpload = React.createRef();
    }

    render(){
        const {
            products,
            remove,
            index,
            errors,
            touched,
            setFieldValue
        } = this.props;

        return (
            <>
                <hr/>
                <div className="d-flex align-self-end">
                    { index > 0 ? (
                        <button type={"button"} onClick={() => remove(index)}>
                            X
                        </button>
                    ) : ''}
                </div>

                <div className="row mb-5" style={{paddingRight:25, paddingLeft:25, paddingTop:10, paddingBottom:10}}>
                    {/*begin::Col*/}
                    <div className="col-md-6 fv-row">
                        {/*begin::Label*/}
                        <label htmlFor={`products.${index}.name`} className="required fs-5 fw-bold mb-2" style={{paddingBottom:10}}>Product Name </label>
                        {/*end::Label*/}
                        {/* begin::Field */}
                        <Field name={`${products}.${index}.name`} className="form-control form-control-solid" placeholder={"e.g Dell"} />
                        <ErrorMessage
                            name={`products.${index}.name`}
                            component="div"
                            className="field-error is-invalid"
                        >
                          {msg => <div style={{color: "red"}}>{msg}</div>}
                        </ErrorMessage>
                        {/* end::Field*/}
                    </div>

                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-md-6 fv-row">
                        {/*end::Label*/}
                        <label htmlFor={`${products}.${index}.prod_price`} className="required fs-5 fw-bold mb-2" style={{paddingBottom:10}}>Product Price </label>
                        {/*end::Label*/}
                        {/* begin::Field */}
                        <Field name={`${products}.${index}.prod_price`} className="form-control form-control-solid" placeholder={"e.g 2000"} />
                        {/* end::Field*/}
                        <ErrorMessage
                            name={`${products}.${index}.prod_price`}
                        >
                            {msg => <div style={{color: "red"}}>{msg}</div>}
                        </ErrorMessage>
                    </div>

                    {/*end::Col*/}
                    <div className="col-md-6 fv-row" style={{paddingBottom:10, paddingTop:20}}>
                        {/*end::Label*/}
                        <label  htmlFor={`${products}.${index}.prod_image`} className="required fs-5 fw-bold mb-2" style={{paddingBottom:10}} >Product Image </label>
                        {/*end::Label*/}
                        {/* begin::Field */}
                        <input
                            name={`${products}.${index}.prod_image`}
                            type={"file"}
                            className="form-control form-control-solid"
                            onChange={(event) => {
                                event.preventDefault();
                                let reader = new FileReader();
                                const file = event.target.files[0];
                                if(file){
                                    reader.readAsDataURL(file);
                                    setFieldValue(`${products}.${index}.prod_image`,file)
                                }
                            }}
                            ref={this.fileUpload}
                        />
                        {/* end::Field*/}
                        <ErrorMessage
                            name={`${products}.${index}.prod_image`}
                        >
                            {msg => <div style={{color: "red"}}>{msg}</div>}
                        </ErrorMessage>
                    </div>

                    {/*begin::Col*/}
                    {/*<div className="col-md-6 fv-row" style={{paddingBottom:10, paddingTop:20}}>*/}
                    {/*    /!*end::Label*!/*/}
                    {/*    <label  htmlFor={`${products}.${index}.prod_specs`} className="fs-5 fw-bold mb-2" style={{paddingBottom:10}}>Product Specification </label>*/}
                    {/*    /!*end::Label*!/*/}
                    {/*    /!* begin::Field *!/*/}
                    {/*    <Field name={`${products}.${index}.prod_specs`} className="form-control form-control-solid" placeholder={"e.g Intel"} />*/}
                    {/*    /!* end::Field*!/*/}
                    {/*    <ErrorMessage*/}
                    {/*        name={`${products}.${index}.prod_specs`}*/}
                    {/*    >*/}
                    {/*        {msg => <div style={{color: "red"}}>{msg}</div>}*/}
                    {/*    </ErrorMessage>*/}
                    {/*</div>*/}
                    {/*end::Col*/}

                    {/*begin::Col*/}
                    <div className="col-md-6 fv-row" style={{paddingBottom:10, paddingTop:20}}>
                        {/*end::Label*/}
                        <label htmlFor={`${products}.${index}.prod_desc`} className="required fs-5 fw-bold mb-2" style={{paddingBottom:10}}>Product Description </label>
                        {/*end::Label*/}
                        {/* begin::Field */}
                        <Field as={'textarea'} rows="4"  name={`${products}.${index}.prod_desc`} className="form-control form-control-solid" placeholder={"e.g Fairly Used"} />
                        
                        {/* end::Field*/}
                        <ErrorMessage
                            name={`${products}.${index}.prod_desc`}
                        >
                            {msg => <div style={{color: "red"}}>{msg}</div>}
                        </ErrorMessage>
                    </div>
                    {/*end::Col*/}
                </div>
            </>
        );
    }

}


export default ProductField;
