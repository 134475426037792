import React from 'react';
import { useParams } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallBack } from '../../Components';

import {
    DisputeForm,

} from '../component';

export default function Dispute({ ...props }){
    const { trans_id } = useParams();

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallBack}
            >
            <DisputeForm trans_id={trans_id}/>
        </ErrorBoundary>
    );
}