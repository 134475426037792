import React from 'react';
import { useParams } from 'react-router-dom';
import { TransactionDetails } from "../../Components";
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallBack } from '../../Components';

function TransactionStatus(props){

    const {
        action,
        trans_id
    } =useParams();

    return (
        <ErrorBoundary
          FallbackComponent={ErrorFallBack}
        >
            <TransactionDetails action={action} trans_id={trans_id} />
        </ErrorBoundary>
    )
}

export default TransactionStatus;
