import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { transactionReducer } from "../app/modules/Transaction/_redux/TransactionRedux";
import { DisputeReducer } from "../app/modules/Dispute/_redux/DisputeRedux";
import { banksReducer } from "../app/modules/Account/_redux/AccountRedux";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  transactionStore: transactionReducer,
  disputeStore: DisputeReducer,
  bankStore: banksReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
