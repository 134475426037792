/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React,{useState} from "react";
// https://github.com/conorhastings/react-syntax-highlighter#prism
// See https://github.com/PrismJS/prism-themes
import _ from "lodash";

import { CardDeck, Card } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { Notice } from "../../../../_metronic/_partials/controls";

function NewTransaction({ ...props }) {
    let history = useHistory();
    const [select, setSelect] = useState({
        product: "",
        service: ""
    });

    const handleChange =({ target: {name, value}})=> {
        setSelect(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleProceed =(e, type) => {
        e.preventDefault();
        if (!_.isUndefined(select[type]) && select[type].length > 0){
            history.push(select[type]);
        }
    }

    return (
        <>
            <Card style={{marginBottom: "10px"}}>
                {/* padding: "10px", */}
                <Card.Title style={{ paddingTop:20, paddingLeft:25}}>
                        New Transaction
                </Card.Title>
            </Card>
                
            <CardDeck>
                <Card style={{height : "50%"}}>
                    <Card.Body>
                        <Card.Title>Product</Card.Title>

                        <p style={{alignItems: "baseline"}}>
                            
                            <input type="radio" name={"product"} onChange={handleChange} style={{alignSelf: "baseline"}} value={"/product-buy"} />

                             <span style={{paddingLeft:5}} >Buy </span> 
                            
                            
                        </p>

                        
                        <p style={{alignSelf: "baseline"}}>
                            <input type="radio" name={"product"} onChange={handleChange} value={"/product-sale"}/><span style={{paddingLeft:5}} >Sell  </span>
                        </p>
                    </Card.Body>
                    <Card.Footer>
                        <button type="submit" onClick={(e) => handleProceed(e,"product")} className="btn btn-primary mr-2">Proceed</button>
                        <Link to={"/overview"} className="btn btn-secondary">Cancel</Link>
                    </Card.Footer>
                </Card>

                    {/*
                       <input type="radio" id="html" name="fav_language" value="HTML">
                     <label for="html">HTML</label><br>
                     <input type="radio" id="css" name="fav_language" value="CSS">
                     <label for="css">CSS</label><br>
                      <input type="radio" id="javascript" name="fav_language" value="JavaScript">
                     <label for="javascript">JavaScript</label>

                        
                    */}

                <Card style={{height : "50%"}}>
                    <Card.Body>
                        <Card.Title>Service</Card.Title>
                        <p style={{alignItems: "baseline"}}>
                            <input type="radio" name={"service"} onChange={handleChange} style={{alignSelf: "baseline"}} value={"/service-buy"}/><span style={{paddingLeft:5}} >Buy </span>
                        </p>
                        <p style={{alignSelf: "baseline"}}>
                            <input type="radio" name={"service"} onChange={handleChange} value={"/service-sale"}/><span style={{paddingLeft:5}} >Sell  </span>
                        </p>
                    </Card.Body>
                    <Card.Footer>
                        <button type="submit" onClick={(e) => handleProceed(e, "service")} className="btn btn-primary mr-2">Proceed</button>
                        <Link to={"/overview"} className="btn btn-secondary">Cancel</Link>
                    </Card.Footer>
                </Card>
            </CardDeck>
        </>
    );
}

export default NewTransaction;
