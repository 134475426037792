import React from "react";
import Loading from "./Loading";
import PropTypes from "prop-types";

function PreLoader({
                       type,
                       color,
                       loading,
                       children,
                       ...props
}){
    return (
        <>
            {
                loading
                    ?
                <Loading type={type} color={color} />
            :
                children
            }
        </>
    );
}

PreLoader.propTypes = {
    type: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default PreLoader;
