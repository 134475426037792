import React from "react";
import { connect } from "react-redux";
import {Card} from "react-bootstrap";
import PropTypes from "prop-types";

function RestrictUserAccess({
                                         user,
                                         roles,
                                         children,
                                         ...props }){
    return (
        <>
            {
                !roles.includes(user.role)
                    ?
                    <Card className={'d-flex justify-content-center align-items-center w-100 h-100'}>
                        <h5>You are not allowed to view this page :)</h5>
                    </Card>
                    :
                    <>
                        {children}
                    </>

            }
        </>
    )
}

RestrictUserAccess.propTypes = {
   roles: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
}

export default connect(state => {
    const { user } = state.auth;
    return { user };
})(RestrictUserAccess);