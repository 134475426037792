import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  updateToken: "[Update Token] Action",
  clearToken: "[Clear Token] Action",
  SetReferral: "[SetReferral] Action",
  updateReferralPoints: "[updateReferralPoints] Action",
  updateReferralCount: "[updateReferralCount] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "vahlid-auth", whitelist: ["authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }
        case actionTypes.updateReferralPoints: {
            return { ...state, user: { ...state.user, referralPoints: action.payload } }
        }
      case actionTypes.updateReferralCount: {
        return { ...state, user: {...state.user, referralCount: action.payload }}
      }
      case actionTypes.SetReferral: {
        return { ...state, user: { ...state.user, referralCode: action.payload } }
      }
      case actionTypes.updateToken: {
        return { ...state, authToken: action.payload };
      }
      case actionTypes.clearToken: {
        return { ...state, authToken: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }
      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }
      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }
      default:
        return state;
    }
  }
);

export const actions = {
         login: (authToken) => ({
           type: actionTypes.Login,
           payload: { authToken },
         }),
         register: (authToken) => ({
           type: actionTypes.Register,
           payload: { authToken },
         }),
         clearToken: () => ({ type: actionTypes.clearToken }),
         logout: () => ({ type: actionTypes.Logout }),
         requestUser: () => ({
           type: actionTypes.UserRequested,
         }),
         fulfillUser: (user) => ({
           type: actionTypes.UserLoaded,
           payload: { user },
         }),
         setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
         updateToken: (token) => ({ type: actionTypes.updateToken, payload: token }),
         SetReferralAction: (payload) => ({ type: actionTypes.SetReferral, payload }),
         updateReferralPoints: payload => ({ type: actionTypes.updateReferralPoints, payload}),
         updateReferralCount: (payload) => ({ type: actionTypes.updateReferralCount, payload }),
       };

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { user } = yield getUserByToken();
    yield put(actions.fulfillUser(user));
  });
}
