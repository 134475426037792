import React, { Component } from 'react';
import _ from 'lodash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { io } from 'socket.io-client';
import { connect } from 'react-redux';
// RCE CSS
import 'react-chat-elements/dist/main.css';
import { Message } from 'react-chat-ui';

import {
    Container,
    Row,
    Col
} from 'react-bootstrap';
import { Button } from 'react-chat-elements';

import {
    ChatFeed,
    InputField,
    ConversationListItem,
    Button as CustomButton,
    PreLoader,
    LockDisputeChat,
} from "../../Components";

import {
    getDisputeByDisputeID,
    getTransactionByTransID,
    getMessagesForDispute,
    updateDisputeStatus,
} from '../_redux/DisputeCRUD';
import { actions as transActions } from '../../Transaction/_redux/TransactionRedux';
import { actions } from '../_redux/DisputeRedux';

//messenger chat
// import "./Chat.css";

class Chat extends Component{
    constructor(props) {
        super(props);
        this.SERVER = process.env.REACT_APP_SOCKET_URL;
        this.socket = io(this.SERVER);
        this.state = {
           buttonLoader: false,
           loading: true,
           is_typing: false,
           status: null,
           session: [],//harbours all connected users
           body: "",//`current user message body
           messages: [], //harbours all messages
           apiErrorMessage : null //in case of any possible error on fetch request
        };
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextPost = this.handleTextPost.bind(this);
        this.handleResolveDispute = this.handleResolveDispute.bind(this);
    }

    async componentDidMount(){
        try {
            const {
                dispute_id,
                trans_id,
                FetchDisputeSuccessfulAction,
                FetchTransactionSuccessFull,
                user,
                dispute,
            } = this.props;
            const { username, vahlid_id, photo } = user;
            const res = await getDisputeByDisputeID(dispute_id);
            const response = await getTransactionByTransID(trans_id);
            //dispatch transaction to the store
            FetchTransactionSuccessFull(response.data.data);
            //dispatch dispute to store
            FetchDisputeSuccessfulAction(res.data.data);
            //get the last 25 messages for dispute
            const { data } = await getMessagesForDispute(res.data.data._id);
            const messages = data.data.map(msg => {
                const { author, message, senderName } = msg;
                return new Message({
                        id: author === user._id ? 0 : 1, //position messages based on the user
                        message,
                        senderName
                    });
            });

            //disable loading when all data are ready
            this.setState({
                ...this.state,
                loading: false,
                messages
            });

            //ping server for websocket
            this.socket.emit('connect-user',{
                username,
                photo,
                vahlid_id,
                dispute_id,
                status: `${username} joined`,
            });

            this.socket.on('user-connected',(data) => {
                this.setState({
                    ...this.state,
                    session: [ ...this.state.session, data.data]
                });
            });


            //listen to the typing event
            this.socket.on('typing',(data) => {
                // display the user that is currently typing
               const { status } = data.data;
                this.setState({
                    ...this.state,
                    status,
                });
            });

            //snap saved
            this.socket.on('saved',(data) => {
                const { senderName, message} = data;
               //push to the stack
                this.setState({
                   ...this.state,
                   messages: [
                       ...this.state.messages,
                       new Message({
                           id: 1, //other users in the chat
                           message,
                           senderName
                       }),
                   ],
                   body: "" ,
                   status: "",
                });
            });

        }catch (e) {
            //handle possible error
            console.log(e.message)
            this.setState({
                apiErrorMessage: e.message,
            });
        }
    }

    handleTextChange(event){
         const { body } = this.state;
         const { user, dispute_id } = this.props;
         const { username, vahlid_id } = user;
         //update the current message body
         this.setState({
            ...this.state,
            body: event.target.value
         });
         //dispatch type event
         this.socket.emit('type',{
            username,
            vahlid_id,
            dispute_id,
            status: `${username} typing`,
         });
     }

    handleTextPost = (event) =>{
         const { body } = this.state;
         const { user, dispute_id, dispute } = this.props;
         const { _id, username } = user;
         //check if a message was entered
         if(!_.isEmpty(body)){
             //ask server to notify others
             this.socket.emit('post-message',{
                 dispute_id: dispute._id,
                 author: _id,
                 senderName: username,
                 message: body,
             });
             //push the (rcu) current stack
             this.setState({
                 ...this.state,
                 messages: [
                     ...this.state.messages,
                     new Message({
                         id: 0,
                         message: body,
                         senderName: username //not really necessary for the current socket
                     }),
                 ],
                 body: '',
                 status: '',
             });
         }

     }

    handleResolveDispute = async (event) => {
        const { dispute, FetchDisputeSuccessfulAction } = this.props;
        event.preventDefault();
        try {
            const MySwal = withReactContent(Swal)
            const { value } = await MySwal.fire({
                title: <p>Are you sure?</p>,
                icon: 'question',
                text: 'Are you sure?, Resolving dispute will lock the screen.',
                showCancelButton: true,
            });

            if (value){
                this.setState(prevState => ({
                    ...prevState, buttonLoader: true,
                }));
                const data = (await updateDisputeStatus(dispute._id,{ status: "resolved"})).data.data.data;
                //dispatch transaction to the store
                FetchDisputeSuccessfulAction(data);
                this.setState(prevState => ({
                    ...prevState, buttonLoader: false,
                }));
            }
        }catch (e) {
            this.setState(prevState => ({
                ...prevState, buttonLoader: false,
            }));
        }

    }

     render(){
       const { dispute } = this.props;
        //
        const {
            loading,
            status,
            buttonLoader,
            session,
            is_typing,
            body,
            messages,
            apiErrorMessage
        } = this.state;
         //throw error to be caught
        // by the ClassErrorBoundary Component
        if (apiErrorMessage) throw new Error(apiErrorMessage);

         return (
             <PreLoader
                 loading={loading}
                 type={'spinningBubbles'}
                 color={'#005C6B'}
             >
                 <LockDisputeChat status={dispute.status}>
                     <Container>
                         <Row>
                             <Col md={9} style={{ backgroundColor:"#FFFFFF"}}>
                                 <ChatFeed
                                     messages={messages} // Array: list of message objects
                                     isTyping={is_typing} // Boolean: is the recipient typing
                                     showSenderName={true} // show the name of the user who sent the message
                                     bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                                     height={450}
                                     // JSON: Custom bubble styles
                                     bubbleStyles={
                                         {
                                             text: {
                                                 fontSize: 13
                                             },
                                             chatbubble: {
                                                 borderRadius: 10,
                                                 padding: 10,
                                                 backgroundColor: '#005C6B'
                                             }
                                         }
                                     }
                                 />
                                 <InputField
                                     placeholder="Type messages here..."
                                     onChange={this.handleTextChange}
                                     defaultValue={body}
                                     value={body}
                                     rightButtons={
                                         <Button
                                             color='white'
                                             backgroundColor='#005C6B'
                                             text='Send'
                                             onClick={this.handleTextPost}
                                         />
                                     }
                                 />

                                 {/*<Card className={'px-2'}>*/}
                                 {/*</Card>*/}
                             </Col>
                             <Col md={3}>
                                 {session.map((user,index) => (
                                     <ConversationListItem
                                         key={index}
                                         // photo={"https://thumbs.dreamstime.com/b/businessman-icon-vector-male-avatar-profile-image-profile-businessman-icon-vector-male-avatar-profile-image-182095609.jpg"}
                                         photo={user.photo}
                                         name={user.username}
                                         text={user.status}
                                     />
                                 ))}
                                 <CustomButton
                                     loading={buttonLoader}
                                     text={"Resolve Dispute"}
                                     onClick={this.handleResolveDispute}
                                 />
                             </Col>
                         </Row>
                     </Container>
                 </LockDisputeChat>
             </PreLoader>
             );
     }
}

const mapStateToProps = state => {
   const { auth, disputeStore } = state;
   return {
       user : auth.user,
       dispute: disputeStore.dispute
   };
}

export default connect(mapStateToProps, {
    ...actions,
    ...transActions
})(Chat);
