/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import  { connect } from "react-redux";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import "./AsideMenuList.css";


function AsideMenuList({ user, layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const disputeAccessRoles = ["agent", "admin", "superadmin" ];

  return (
    <>
      {/* begin::Menu Nav */}
      <div > 
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/overview", false)} `}
          aria-haspopup="true"
        >
          <NavLink className="menu-link"  to="/overview">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/svg_icons/home.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/transaction", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/transaction">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/svg_icons/wallet.svg")} />
            </span>
            <span className="menu-text">Transaction</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        <li
            className={`menu-item ${getMenuItemActive("/dispute", false)}`}
            aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dispute">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/svg_icons/dispute.svg")} />
            </span>
            <span className="menu-text">Disputes</span>
          </NavLink>
        </li>

        <li
            className={`menu-item ${getMenuItemActive("/account", false)}`}
            aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/account">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/svg_icons/account.svg")} />
            </span>
            <span className="menu-text">Account</span>
          </NavLink>
        </li>

        <li
            className={`menu-item ${getMenuItemActive("/referral", false)}`}
            aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/referral">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/svg_icons/set.svg")} />
            </span>
            <span className="menu-text">Referral</span>
          </NavLink>
        </li>

        <li
            className={`menu-item ${getMenuItemActive("/developer", false)}`}
            aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/developer">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/svg_icons/set.svg")} />
            </span>
            <span className="menu-text">Developer Settings</span>
          </NavLink>
        </li>

        {/* Agent Level */}
        {
          disputeAccessRoles.includes(user.role)
            &&
          <li
              className={`menu-item menu-item-submenu  ${getMenuItemActive(
                  "/dispute/agent",
                  true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink
                className="menu-link menu-toggle"
                to="/dispute/agent"
            >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/svg_icons/account.svg")} />
            </span>
              <span className="menu-text">Agents</span>
              <i className="menu-arrow" style={{marginTop: 12, marginBottom: 12}} />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                {/*begin::3 Level*/}
                <li
                    className={`menu-item  ${getMenuItemActive(
                        "/dispute/agent"
                    )}`}
                    aria-haspopup="true"
                >
                  <NavLink
                      className="menu-link"
                      to="/dispute/agent"
                  >
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/svg_icons/dispute.svg")} />
                  </span>
                    <span className="menu-text">Disputes</span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}
              </ul>
            </div>
          </li>
        }
        {/* Agent Level */}
      </ul>
    </div>


    <div className="logoutDiv" style={{ position: "fixed", bottom: "0", display:"block", }}>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
            className={`menu-item ${getMenuItemActive("/google-material", false)}`}
            aria-haspopup="true" 
          
        >
          <NavLink className="menu-link" to="/logout">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/svg_icons/log-out.svg")} />
            </span>
            <span className="menu-text">Logout</span>
          </NavLink>
        </li>

        </ul>
    </div>
    </>
  );
}

export default connect((state) => {
  const { user } = state.auth;
  return { user }
})(AsideMenuList);