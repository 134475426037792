import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { put, takeLatest } from "redux-saga/effects";
// import { fetchBanks } from "../_redux/AccountCRUD";

export const actionTypes = {
  FetchBanks: "[Fetch Bank Accounts] Action",
};

const initialAuthState = {
  banks: [],
};

export const banksReducer = persistReducer(
  { storage, key: "vahlid-banks" },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.FetchBanks: {
        const { banks } = action.payload;
        return { banks };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchBanks: (banks) => ({
    type: actionTypes.FetchBanks,
    payload: { banks }
  }),
};

// export function* saga() {
//   yield takeLatest(actionTypes.FetchBanks, function* loginSaga() {
//     const banks = yield fetchBanks();
//     yield put(actions.fetchBanks(banks));
//   });
// }