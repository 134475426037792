import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {Card, CardDeck} from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { SRLWrapper } from "simple-react-lightbox";
import { useErrorHandler } from "react-error-boundary";

import { default as TransactionFeedback } from "./TransactionFeedback";
import { getTransactionById } from '../Transaction/_redux/TransactionCRUD';
import ViewThumb from '../../../_metronic/thumbs/view.PNG';
import { actions } from '../Transaction/_redux/TransactionRedux'
import  { Loading } from '../Components';
import axios from "axios";

const TransactionDetails = ({
                                user,
                                type,
                                action,
                                transaction,
                                FetchTransactionSuccessFull,
                                trans_id,
                                ...props
}) => {
    const {
        delivery_addr,
        products,
        is_service,
        seller,
        buyer
    } = transaction;

    const [loading, setLoading ] = useState(true);
    const handleError = useErrorHandler();

    useEffect(() => {
        // prevent memory leakage
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        getTransactionById({ trans_id, source }).then(res => {
            FetchTransactionSuccessFull(res.data.data);
            setLoading(false);
        }).catch(err => {
            //flash some react notif
            setLoading(false);
            handleError(err);
        });
        return () => source.cancel("Request Cancelled");
    },[trans_id]);

    const columns = [
        {
            dataField : '_id',
            text : 'ID',
            hidden : true
        },
        {
            dataField : 'prod_image',
            text : 'Image',
            hidden : true
        },
        {
            dataField: 'name',
            text: 'Name'
        },
        {
            dataField: 'prod_price',
            text: 'Price'
        },
        {
            dataField: 'prod_desc',
            text : 'Description'
        },
        {
            dataField: 'prod_specs',
            text: 'Specification'
        },
        {
            dataField: 'link',
            text : 'Image',
            hidden: !!is_service,
            formatter : (cell, row, rowIndex) => {
                return (
                        <a href={row.prod_image}><img src={ViewThumb} alt={"View Products"} /></a>
                )
            }
        }
    ];

    return (<>
        {
            loading ?
                <Loading type={'spinningBubbles'} color={'#005C6B'}/>
                :
                <SRLWrapper>
                    <CardDeck>
                        <TransactionFeedback transaction={transaction} />
                        <Card>
                            <Card.Body>
                                <Card.Title>Service Details</Card.Title>
                                {user._id  === seller._id ?
                                    <>
                                        <p>Buyer Details</p>
                                        <p>Vahlid ID : {buyer.vahlid_id}</p>
                                        <p>Buyer Name : {buyer.username}</p>
                                        <hr/>
                                        <p>Seller Details</p>
                                        <p>Vahlid ID : {seller.vahlid_id}</p>
                                        <p>Seller Name : {seller.username}</p>
                                    </> :
                                    <>
                                        <p>Seller Details</p>
                                        <p>Vahlid ID : {seller.vahlid_id}</p>
                                        <p>Seller Name : {seller.username}</p>
                                        <hr/>
                                        <p>Buyer Details</p>
                                        <p>Vahlid ID : {buyer.vahlid_id}</p>
                                        <p>Buyer Name : {buyer.username}</p>
                                    </>
                                }
                                <hr/>
                                <p><span>Delivery Address : </span> {delivery_addr}</p>
                                <p><span>Transaction Type : </span> {is_service ? "Service" : "Product"}</p>
                                <BootstrapTable
                                    keyField={'_id'}
                                    data={products}
                                    columns={columns}
                                />
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </SRLWrapper>
        }
    </>);
}

const mapStateToProps = state => {
    const { user } = state.auth;
    const { transaction } = state.transactionStore;
    return { user, transaction };
}
export default connect(mapStateToProps,actions)(TransactionDetails);
