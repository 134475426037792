/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, {useCallback, useEffect, useState} from "react";
import {
    Formik,
    Field,
    Form,
    FieldArray
} from 'formik';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Button } from "../../Components";
import { connect } from 'react-redux';
import {NotificationContainer, NotificationManager} from 'react-notifications';

// https://github.com/conorhastings/react-syntax-highlighter#prism
// See https://github.com/PrismJS/prism-themes

//import { Notice } from "../controls";
//import { useHistory } from "react-router";

import { createServiceSaleTransaction } from "../_redux/TransactionCRUD";
import {ServiceField} from "../../Components";
import { Tooltip } from "reactstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import {
    getReferralCount
} from "../../Auth/_redux/authCrud";

import {
    actions
} from "../../Auth";

//css
import 'react-notifications/lib/notifications.css';
import {useErrorHandler} from "react-error-boundary";


function ServiceSale({
                         user,
                         updateReferralCount,
                         ...props}) {
    const [loading, setLoading] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { REACT_APP_MAX_ALLOWED_REFERRAL } = process.env;

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const handleError = useErrorHandler();
    const fetchReferralCount = useCallback( () => {
        getReferralCount().then(response => {
            updateReferralCount(response.data.data.referralCount)
        },handleError);
    },[getReferralCount]);

    useEffect(() => {
        fetchReferralCount();
    },[fetchReferralCount]);

    const ServiceSchema = Yup.object().shape({
        pay_deadline : Yup.date().required("The payment deadline field is required")
            .test('current_date',"The payment deadline cannot be in the past",function(value){
            return new Date(value).getTime() > (Date.now() - 86400000);
        }),
        delivery_addr : Yup.string().required("Delivery address field is required"),
        vahlid_id : Yup.string().required("Vahlid ID/Email is required")
            .test("circular_transaction","You cannot transact against your Account",function(value){
                return value !== String(user.vahlid_id) && value !== String(user.email); //test to prevent the user to transact again his/her account (circular transaction)
            }),
        deadline_action : Yup.string().required(),
        products : Yup.array().of(
            Yup.object().shape({
                name : Yup.string().required("The service name field is required"),
                prod_desc : Yup.string().required("The product description field is required"),
                prod_price : Yup.number().typeError("Invalid price format").required("The service price field is required")
            })
        )
    })

    return (
      <>
        <div className="row align-center" style={{ marginBottom: 20 }}>
          <div className="card card-custom col-md-12 col-lg-12 col-sm-12">
            <div className="card-header">
              <h3 className="card-title" style={{ fontSize: 20 }}>
                Service Sale
              </h3>
            </div>
          </div>
        </div>

        <div className="row align-center">
          <div className="card card-custom col-md-12 col-lg-12 col-sm-12">
            <div className="card-header">
              <h3 className="card-title">I'm Selling a Service</h3>
              <div className="card-toolbar">
                <div className="example-tools justify-content-center">
                  <span
                    className="example-toggle"
                    data-toggle="tooltip"
                    title="View code"
                  />
                  <span
                    className="example-copy"
                    data-toggle="tooltip"
                    title="Copy code"
                  />
                </div>
              </div>
            </div>

            {/*begin::Form*/}
            <Formik
              validationSchema={ServiceSchema}
              initialValues={{
                delivery_addr: "",
                pay_deadline: "",
                deadline_action: "delete",
                vahlid_id: "",
                promoCode: "",
                products: [
                  {
                    name: "",
                    prod_price: "",
                    prod_desc: "",
                  },
                ],
              }}
              onSubmit={(values) => {
                //spin btn
                setLoading(true);
                createServiceSaleTransaction(values)
                  .then((service) => {
                    const { history } = props;
                    const tx_ref = service.data.data.trans_id;
                    if (tx_ref) {
                      //the action in this case is buy
                      history.push(`/transaction/${tx_ref}/status`);
                    } else {
                      setLoading(false);
                    }
                  })
                  .catch((err) => {
                    //use react Notification Here
                    setLoading(false);
                      NotificationManager.error(`${err.response.data.message}`,'Error');
                  });
              }}
            >
              {({ errors, values, touched }) => (
                <Form>
                  {/*begin::Input group*/}
                  <div
                    className="row mb-5"
                    style={{
                      paddingRight: 25,
                      paddingLeft: 25,
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    {/* begin:General Details for all products */}
                    <div className="col-md-6 fv-row">
                      {/*begin::Label*/}
                      <label
                        className="required fs-5 fw-bold my-2"
                        style={{ paddingBottom: 10 }}
                      >
                        Delivery Address{" "}
                      </label>
                      {/*end::Label*/}
                      <Field
                        name={"delivery_addr"}
                        id={"delivery_addr"}
                        className="form-control form-control-solid"
                        placeholder={"e.g Ikeja GRA"}
                      />
                      {errors.delivery_addr && touched.delivery_addr && (
                        <div style={{ color: "red" }}>
                          {errors.delivery_addr}
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 fv-row">
                      {/*begin::Label*/}
                      <label
                        className="required fs-5 fw-bold my-2"
                        style={{ paddingBottom: 10 }}
                      >
                        Payment Deadline{" "}
                      </label>
                      {/*end::Label*/}
                      <Field
                        name={"pay_deadline"}
                        type={"date"}
                        className="form-control form-control-solid"
                      />
                      {errors.pay_deadline && touched.pay_deadline && (
                        <div style={{ color: "red" }}>
                          {errors.pay_deadline}
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 fv-row">
                      {/*begin::Label*/}
                      <label
                        className="required fs-5 fw-bold my-2"
                        style={{ paddingBottom: 10, paddingTop: 20 }}
                      >
                        IF I DON'T PAY BEFORE DEADLINE{" "}
                      </label>
                      {/*end::Label*/}
                      <Field
                        as={"select"}
                        name={"deadline_action"}
                        className="form-control form-control-solid"
                      >
                        <option value="delete">Delete</option>
                        <option value="nothing">Do Nothing</option>
                      </Field>
                    </div>
                    <div className="col-md-6 fv-row">
                      {/*begin::Label*/}
                      <label
                        className="required fs-5 fw-bold my-2"
                        style={{ paddingBottom: 10, paddingTop: 20 }}
                      >
                        Seller Details{" "}
                        <img
                          alt=""
                          height="18"
                          width="18"
                          src={toAbsoluteUrl("/media/misc/tooltip.png")}
                          id="info"
                        />
                        <Tooltip
                          placement="top-end"
                          isOpen={tooltipOpen}
                          target="info"
                          toggle={toggle}
                        >
                          For anonymous transactions, enter the email of the
                          anonymous buyer.
                        </Tooltip>
                      </label>
                      {/*end::Label*/}
                      <Field
                        name={"vahlid_id"}
                        className="form-control form-control-solid"
                        placeholder={"Vahlid ID/Email"}
                      />
                      {errors.vahlid_id && touched.vahlid_id && (
                        <div style={{ color: "red" }}>{errors.vahlid_id}</div>
                      )}
                    </div>
                      {/*  begin promo code*/}
                      {
                          Number(user.referralCount) < Number(REACT_APP_MAX_ALLOWED_REFERRAL)
                          &&
                          <div className="col-md-6 fv-row mt-2">
                              {/*begin::Label*/}
                              <label
                                  className="fs-5 fw-bold my-2"
                                  style={{ paddingBottom: 10 }}
                              >
                                  Referral Code{" "}
                              </label>

                              {/*end::Label*/}
                              <Field
                                  name={"promoCode"}
                                  id={"promoCode"}
                                  className="form-control form-control-solid"
                              />
                              {errors.promoCode && touched.promoCode && (
                                  <div style={{ color: "red" }}>
                                      {errors.promoCode}
                                  </div>
                              )}
                          </div>
                      }
                      {/*  end promocode*/}

                      {/* end:General Details for all products */}
                  </div>
                  {/*end::Input group*/}

                  <FieldArray name={"products"}>
                    {({ insert, remove, push }) => (
                      <>
                        {values.products.length > 0 &&
                          values.products.map((product, index) => (
                            <ServiceField
                              key={index}
                              products={"products"}
                              remove={remove}
                              index={index}
                              errors={errors}
                              touched={touched}
                            />
                          ))}
                        <div className="card-footer d-flex justify-content-between">
                          <div className="div">
                            <Button
                              loading={loading}
                              text={"Proceed"}
                              className={"btn btn-primary mr-2"}
                            />
                            <Link
                              to={"/new-transaction"}
                              className="btn btn-secondary"
                            >
                              Cancel
                            </Link>
                          </div>
                          <div className="div">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() =>
                                push({
                                  name: "",
                                  prod_price: "",
                                  prod_specs: "",
                                  prod_desc: "",
                                })
                              }
                            >
                              Add Service
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </Form>
              )}
            </Formik>
            {/*end::Form*/}
          </div>
        </div>
          <NotificationContainer/>
      </>
    );
}
const mapStateToProps = state => {
    const { user } = state.auth;
    return { user }
}

export default connect(mapStateToProps,actions)(ServiceSale);