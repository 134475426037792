//upgrade react chat ui library to suit our need
//with a little bit of modifications
import * as React from 'react';
import PropTypes from 'prop-types';
//rcu
import {
    BubbleGroup,
    // DefaultChatBubble,
    ChatInput,
    Message,
    ChatBubble,
    ChatBubbleProps,
} from 'react-chat-ui';
import styles from './styles';

// React component to render a complete chat feed
class ChatFeed extends React.Component {
    props;
    chat: {
        scrollHeight: number;
        clientHeight: number;
        scrollTop: number;
    };
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom() {
        const scrollHeight = this.chat.scrollHeight;
        const height = this.chat.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.chat.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    /**
     * Determines what type of message/messages to render.
     */
    renderMessages(messages) {
        const { isTyping, bubbleStyles, chatBubble, showSenderName } = this.props;

        // const ChatBubble = chatBubble || ChatBubble;

        let group = [];

        const messageNodes = messages.map((message, index) => {
            group.push(message);
            // Find diff in message type or no more messages
            if (index === messages.length - 1 || messages[index + 1].id !== message.id) {
                const messageGroup = group;
                group = [];
                return (
                    <BubbleGroup
                        key={index}
                        messages={messageGroup}
                        id={message.id}
                        showSenderName={showSenderName}
                        chatBubble={ChatBubble}
                        bubbleStyles={bubbleStyles}
                    />
                );
            }

            return null;
        });

        // Other end is typing...
        if (isTyping) {
            messageNodes.push(
                <div key="isTyping" style={{ ...styles.chatbubbleWrapper }}>
                    <ChatBubble
                        message={new Message({ id: 1, message: '...', senderName: '' })}
                        bubbleStyles={bubbleStyles}
                    />
                </div>
            );
        }

        // return nodes
        return messageNodes;
    }

    /**
     * render : renders our chatfeed
     */
    render() {
        const { height } = this.props;
        return (
            <div id="chat-panel" style={styles.chatPanel}>
                <div
                    ref={c => {
                        this.chat = c;
                    }}
                    className="chat-history"
                    style={{ ...styles.chatHistory, height }}
                >
                    <div className="chat-messages">
                        {this.renderMessages(this.props.messages)}
                    </div>
                </div>
            </div>
        );
    }
}

ChatFeed.propTypes = {
        bubblesCentered: PropTypes.bool,
        bubbleStyles: PropTypes.object,
        isTyping: PropTypes.bool,
        height: PropTypes.number,
        messages: PropTypes.any,
        showSenderName: PropTypes.bool,
        chatBubble: PropTypes.element
}
export default ChatFeed;