import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
} from "react";

//importing error handler
import { useErrorHandler } from "react-error-boundary";

import { toastInfo } from "../toastInfo";
//importing components
import DrawerBottom from "../DrawerBottom";
import  TooltipCustom  from "../TooltipCustom";
import { Picker } from "emoji-mart";
//importing material-ui
import {
    Hidden,
    Tooltip,
    Fab,
    Slide,
    ClickAwayListener
} from "@material-ui/core";
//importing swal
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

//import react-redux connect
import { connect } from "react-redux";
//import socket
import { io } from 'socket.io-client';
//importing material-ui-icons
import CloseIcon from "@material-ui/icons/Close";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
// import MicIcon from "@material-ui/icons/Mic";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import PhotoIcon from "@material-ui/icons/Photo";
//importing styles
import "emoji-mart/css/emoji-mart.css";
import "./index.css";

//importing dispute CRUD
import {
    getDisputeByDisputeID,
    getTransactionByTransID,
    getMessagesForDispute,
    updateDisputeStatus,
    getDisputeChatImageUploadSignature,
    cloudinaryChatImageUpload,
} from "../../../Dispute/_redux/DisputeCRUD";

//importing global Components
import { Button } from "../../../Components";

//importing actions
import { actions as transActions } from "../../../Transaction/_redux/TransactionRedux";
import { actions } from "../../_redux/DisputeRedux";

const attachFileLists = [
    {
        title: "Photo",
        icon: <PhotoIcon />,
        id: Math.random() * 100000,
    },
];

function ChatFooter({
                        user,
                        trans_id,
                        dispute_id,
                        dispute,
                        FetchDisputeSuccessfulAction,
                        FetchTransactionSuccessFull,
                        FetchDisputeMessagesSuccessfulAction,
                        CreateDisputeMessageSuccessfulAction,
                        fetchDisputeDetails,
                        ...props
}) {
    const handleError = useErrorHandler();
    const [input, setInput] = useState("");
    const [emoji, setEmoji] = useState(false);
    const [fileImageUrl, setFileImageUrl] = useState(null);
    const [drawerBottom, setDrawerBottom] = useState(false);
    const [showAttachFile, setShowAttachFile] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);

    //instantiate the websocket
    const socket = useRef(io(process.env.REACT_APP_SOCKET_URL));
    const { _id, username, photo, vahlid_id } = user;

    //roles allowed to resolve dispute
    const resolvers = ["agent", "superadmin", "admin"];

    //currently of no use for now...
    const attachFile = () => {
        const attachToastId = "attach";
        toastInfo(
            "You can only upload images",
            attachToastId,
            "top-center"
        );
        if (showAttachFile === false) {
            setShowAttachFile(true);
        } else {
            setShowAttachFile(false);
        }
    };

    const addEmoji = (e) => {
        let emoji = e.native;
        setInput(input + emoji);
    };

    const handleEmoticons = () => {
        setEmoji(true);
    };

    const handleEmoticonsClose = () => {
        setEmoji(false);
    };

    const onFileChange = async (e) => {
        const fileSizeToastId = "fileSizeToastId";
        const file = e.target.files[0];
        if (file.size > 4 * 1024 * 1024) {
            toastInfo(
                "File should not exceed 4MB",
                fileSizeToastId,
                "top-center"
            );
        } else {
            if (file.type.match("image.*")){
                const res = await getDisputeChatImageUploadSignature();
                const { cloudname } = res.data.data;
                const cloudinary_upload_url = `https://api.cloudinary.com/v1_1/${cloudname}/auto/upload`;
                setFileImageUrl(await cloudinaryChatImageUpload(cloudinary_upload_url,file,res.data.data));
            }
         }
            setDrawerBottom(true);
        }

    const handleClickAway = () => {
        setShowAttachFile(false);
    };


    useEffect(() => {
        const websocket = socket.current;
        //ping server for websocket
        websocket.emit('connect-user',{
            username,
            photo,
            vahlid_id,
            dispute_id,
            status: `${username} joined`,
        });

        //on resolved dispute event
        websocket.on("resolved",(data) => {
            //stop loader button over here
            setButtonLoader(false);
            //dispatch transaction to the store
            FetchDisputeSuccessfulAction(data);
        });


//message saved
        websocket.on('saved',(msg) => {
            if (msg.author === _id){
                //replace all messages list
                //mark alarm clock decision
                fetchDisputeDetails();
            }else{
                //append the new message
                CreateDisputeMessageSuccessfulAction(msg);
            }
        });

        //clean up on unmount
        // return () => websocket.close();
    },[
        username,
        photo,
        vahlid_id,
        dispute_id,
        fetchDisputeDetails,
        FetchDisputeSuccessfulAction,
    ]);

    const  handleResolveDispute = async (event) => {
        event.preventDefault();
        try {
            const MySwal = withReactContent(Swal)
            const { value } = await MySwal.fire({
                title: <p>Are you sure?</p>,
                icon: 'question',
                text: 'Are you sure?, Resolving dispute will lock the screen.',
                showCancelButton: true,
            });

            if (value){
                //initialize loader button over here...
                setButtonLoader(true);
                const data = (await updateDisputeStatus(dispute._id,{ status: "resolved"})).data.data.data;
                //close other chat screens
                socket.current.emit("resolve-dispute", data );
            }
        }catch (e) {
            //stop loader button over here
            setButtonLoader(false);
        }

    }


    const onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            const urlLink = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

            const { _id, username } = user;
            let msg = {};
            //just in case the user enters a url (link)
            if (urlLink.test(input)){
                msg = {
                    dispute_id: dispute._id,
                    author: _id,
                    senderName: username,
                    url: input,
                    roomId: dispute_id,
                };
                socket.current.emit('post-message',msg);
                CreateDisputeMessageSuccessfulAction(msg);
            }else if(/\S/.test(input)){
                msg = {
                    dispute_id: dispute._id,
                    author: _id,
                    senderName: username,
                    message: input,
                    roomId: dispute_id,
                };
                socket.current.emit('post-message',msg);
                CreateDisputeMessageSuccessfulAction(msg);
            }

            setInput("");
            setEmoji(false);
        }
    };

    return (
        <>
            <div className="chat__footer">
                <DrawerBottom
                    drawerBottom={drawerBottom}
                    setDrawerBottom={setDrawerBottom}
                    fileImageUrl={fileImageUrl}
                    setFileImageUrl={setFileImageUrl}
                    dispute_id={dispute._id}
                    socket={socket}
                />

                <div>
                    <TooltipCustom
                        name="Attach"
                        icon={<AttachFileIcon />}
                        onClick={attachFile}
                    />
                    {showAttachFile ? (
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <div className="chat__attachFile">
                                {attachFileLists.map((attachFileList) => (
                                    <Slide
                                        key={attachFileList.id}
                                        direction="up"
                                        in={true}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        <Tooltip
                                            title={
                                                <span
                                                    style={{ fontSize: "14px", padding: "8px 5px 8px 5px" }}
                                                >
                                                {attachFileList.title}
                                           </span>
                                            }
                                            placement="left"
                                        >
                                            <Fab color="primary" aria-label="person">
                                                <div className="chat__icon">
                                                    <label htmlFor="file-input">
                                                        {attachFileList.icon}
                                                    </label>
                                                    <input
                                                        id="file-input"
                                                        type="file"
                                                        onChange={onFileChange}
                                                        accept="image/*"
                                                    />
                                                </div>
                                            </Fab>
                                        </Tooltip>
                                    </Slide>
                                ))}
                            </div>
                        </ClickAwayListener>
                    ) : null}
                </div>
                <form>
                <textarea
                    required
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Type a message"
                    rows="1"
                    onKeyDown={onEnterPress}
                />
                </form>

                {emoji ? (
                    <Hidden only={["xs"]}>
                        <TooltipCustom
                            name="Close"
                            icon={<CloseIcon />}
                            onClick={() => handleEmoticonsClose()}
                        />
                    </Hidden>
                ) : null}

                <TooltipCustom
                    name="Emoticons"
                    icon={<InsertEmoticonIcon />}
                    onClick={() => handleEmoticons()}
                />
                {emoji ? (
                    <>
                        <Hidden only={["xs"]}>
                            <Picker onSelect={addEmoji} />
                        </Hidden>
                        <Hidden smUp>
                            <ClickAwayListener onClickAway={handleEmoticonsClose}>
                                <Picker onSelect={addEmoji} />
                            </ClickAwayListener>
                        </Hidden>
                    </>
                ) : null}
            </div>
            {
                resolvers.includes(user.role)
                &&
                <Button
                    loading={buttonLoader}
                    type={"button"}
                    text={"Resolve"}
                    className={"btn btn-primary mt-2"}
                    onClick={handleResolveDispute}
                />
            }
        </>
    );
}


const mapStateToProps = (state) => {
  const { auth, disputeStore } = state;
  return {
      user: auth.user,
      dispute: disputeStore.dispute,
  };
}

export default connect(mapStateToProps,{
    ...actions,
    ...transActions,
})(ChatFooter);
