import React from "react";
import ReactLoading from 'react-loading';
import PropTypes from "prop-types";

const NoDataIndication = ({loading, ...props}) => (
    <>
        {
            loading
                ?
                <div className={'d-flex justify-content-center py-4'}>
                    <ReactLoading type={'spokes'} color={"#005C6B"} height={30} width={30} />
                </div>
                :
                <div className={'d-flex justify-content-center'}> No data available</div>
        }
    </>
);

NoDataIndication.propTypes = {
    loading: PropTypes.bool.isRequired
}
export default NoDataIndication;