/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { resetPassword } from "../_redux/AccountCRUD";
import { actions } from "../../Auth/_redux/authRedux";
import { useErrorHandler } from "react-error-boundary";

const passwordFormValidationSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "Password must not be less than 6 characters long.")
    .max(50, "Password must not be more than 50 characters long.")
    .required("Current password is required"),
  newPassword: yup
    .string()
    .min(6, "Password must not be less than 6 characters long.")
    .max(50, "Password must not be more than 50 characters long.")
    .required("New password is required"),
  passwordConfirmation: yup
    .string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Please enter your new password again.")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const SignInMethod = ({ user, clearToken, logout, fulfillUser }) => {
  const [showPasswordForm, setPasswordForm] = useState(false);

  const [loading2, setLoading2] = useState(false);

  const handleError = useErrorHandler();

  const handleSubmit = async(values) => {
    setLoading2(true);
    try {
      await resetPassword(values);
      setLoading2(false);
      clearToken();
      logout();
      fulfillUser(null);
    } catch (error) {
      handleError(error);
    }
  }

  const passwordFormik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: useCallback(handleSubmit, [handleSubmit])
  });

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_signin_method"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Credentials Management</h3>
        </div>
      </div>

      <div id="kt_account_signin_method" className="collapse show">
        <div className="card-body border-top p-9">
          <div className="d-flex flex-wrap align-items-center mb-10 row">
            <div
              id="kt_signin_password"
              className={"col " + (showPasswordForm && "d-none")}
            >
              <div className="fs-6 fw-bolder mb-1">Password</div>
              <div className="fw-bold text-gray-600">************</div>
            </div>

            <div
              id="kt_signin_password_edit"
              className={"flex-row-fluid " + (!showPasswordForm && "d-none")}
            >
              <form
                onSubmit={passwordFormik.handleSubmit}
                id="kt_signin_change_password"
                className="form"
                noValidate
              >
                <div className="row mb-1">
                  <div className="col-lg-4">
                    <div className="fv-row mb-0">
                      <label
                        htmlFor="currentpassword"
                        className="form-label fs-6 fw-bolder mb-3"
                      >
                        Current Password
                      </label>
                      <input
                        type="password"
                        className="form-control form-control-lg form-control-solid "
                        id="password"
                        name="password"
                        onChange={passwordFormik.handleChange}
                      />
                      {passwordFormik.touched.password &&
                        passwordFormik.errors.password && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {passwordFormik.errors.password}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="fv-row mb-0">
                      <label
                        htmlFor="newpassword"
                        className="form-label fs-6 fw-bolder mb-3"
                      >
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control form-control-lg form-control-solid "
                        name="newPassword"
                        {...passwordFormik.getFieldProps("newPassword")}
                      />
                      {passwordFormik.touched.newPassword &&
                        passwordFormik.errors.newPassword && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {passwordFormik.errors.newPassword}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="fv-row mb-0">
                      <label
                        htmlFor="confirmpassword"
                        className="form-label fs-6 fw-bolder mb-3"
                      >
                        Confirm New Password
                      </label>
                      <input
                        type="password"
                        className="form-control form-control-lg form-control-solid "
                        name="confirmPassword"
                        {...passwordFormik.getFieldProps(
                          "passwordConfirmation"
                        )}
                      />
                      {passwordFormik.touched.passwordConfirmation &&
                        passwordFormik.errors.passwordConfirmation && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {passwordFormik.errors.passwordConfirmation}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <button
                    id="kt_password_submit"
                    type="submit"
                    className="btn btn-primary me-2 px-6"
                  >
                    {!loading2 && "Update Password"}
                    {loading2 && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                  <button
                    onClick={() => {
                      setPasswordForm(false);
                      passwordFormik.resetForm()
                    }}
                    id="kt_password_cancel"
                    type="button"
                    className="btn btn-color-gray-400 btn-active-light-primary px-6"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>

            <div
              id="kt_signin_password_button"
              className={"col ms-auto " + (showPasswordForm && "d-none")}
            >
              <button
                onClick={() => {
                  setPasswordForm(true);
                  passwordFormik.resetForm();
                }}
                className="btn btn-light btn-active-light-primary"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { transaction } = state.transactionStore;
  const { user } = state.auth;
  return { transaction, user };
};

export default connect(mapStateToProps, actions)(SignInMethod);
