import axios from "axios";

const { REACT_APP_API_USERS_URL } = process.env;

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/login`;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/register`;
export const UPDATE_REGISTER_URL = `${process.env.REACT_APP_API_URL}/update-register`;
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/password-forgot`;
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;
export const VERIFICATION_URL = `${process.env.REACT_APP_API_URL}/email/verification-notification`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/password-reset`;
export const USER_UPDATE_URL = `${REACT_APP_API_USERS_URL}/update`;


export function login(email, password) {
  // return axios.post(LOGIN_URL, { email, password });
  return axios.post(LOGIN_URL, { email, password }).then(res => {
    return {
      data: {
        authToken: res.headers.token
      }
    };
  });

}

export function register(email, username, password) {
  return axios.post(REGISTER_URL, { email, username, password});
}

export function update_register(firstname, lastname, phone, authToken) {
  return axios.post(UPDATE_REGISTER_URL, { firstname, lastname, phone},{
    headers : {
      Authorization : `Bearer ${authToken}`
    }
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  let res = await axios.get(ME_URL);
  const { data } = res.data;
  return { user : data};
}

export function resendVerificationMail(_id){
  return axios.post(VERIFICATION_URL,{_id});
}

export function resetPassword(data){
  return axios.post(RESET_PASSWORD_URL,data);
}

export const updateUser = async (updateObj) => {
  const response = await axios.patch(USER_UPDATE_URL, updateObj);
  return response.data;
};

export function getReferralCount(){
  return axios.get(`${REACT_APP_API_USERS_URL}/referrals/count`);
}

export function getReferralPoint(){
  return axios.get(`${REACT_APP_API_USERS_URL}/referrals/points`);
}