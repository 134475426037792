import React, { Component } from 'react';

class ClassErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false}
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.log(error, errorInfo);
    }

    render() {

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h3>Oops, We might have broken something :).</h3>;
        }

        return this.props.children;

    }
}

export default ClassErrorBoundary;