import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import AccountPage from "./modules/Account/pages/AccountPage";
import APIPage from "./modules/Developer/pages/APIPage";

//only use the maintenance to lock pages under construction
// import { Maintenance } from "./modules/Components";

// New Transactions
import {
    NewTransaction,
    ProductBuy,
    ProductSale,
    ServiceBuy,
    ServiceSale,
    TransactionStatus,
    Invoice,
    Transaction,
} from "./modules/Transaction";

//Dispute Comps
import {
    Dispute,
    DisputeSession,
    DisputeList,
    UserDisputeList,
} from './modules/Dispute';

//Referral Comps
import {
    Referral,
} from "./modules/Referral";

import { PreventNoAccountTransaction } from "./modules/Components";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one32
    // call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/overview" />
        }

        <ContentRoute path="/overview" component={DashboardPage} />
        <ContentRoute path="/account" component={AccountPage} />
        <ContentRoute path="/developer" component={APIPage} />
        <PreventNoAccountTransaction>
          <ContentRoute
              exact
              path="/dispute/:trans_id/raise"
              component={Dispute}
          />
          <ContentRoute
            exact
            path={"/dispute/:trans_id/:dispute_id/session"}
            component={DisputeSession}
          />
           <ContentRoute
                exact
                path={"/dispute"}
                component={UserDisputeList}
            />

            {/* only allow "agent", "admin", "superadmin" to access this route*/}
            <ContentRoute
                exact
                path="/dispute/agent"
                component={DisputeList}
            />

          {/* Referral routes */}
          <ContentRoute
              exact
              path={"/referral"}
              component={Referral}
          />


          {/*  Transaction routes */}
          <ContentRoute  path="/product-buy" component={ProductBuy} />
          <ContentRoute  path="/product-sale" component={ProductSale} />
          <ContentRoute  path="/service-sale" component={ServiceSale} />
          <ContentRoute  path="/service-buy" component={ServiceBuy} />

          <ContentRoute
            exact
            path={"/transaction/:trans_id/status"}
            component={TransactionStatus}
          />
          <ContentRoute
            exact
            path={"/transaction/:trans_id/invoice"}
            component={Invoice}
          />
          <ContentRoute path="/new-transaction" component={NewTransaction} />
          <ContentRoute exact path={"/transaction"} component={Transaction} />
        </PreventNoAccountTransaction>

        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}

//use react redux