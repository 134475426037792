import React from "react";
import { default as APIKey } from "../cards/APIKey";

export function Developer() {
  return (
    <>
      <APIKey />
    </>
  );
}
