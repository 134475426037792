import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {Card} from "react-bootstrap";
import { Link } from "react-router-dom";


function PreventNoAccountTransaction({
                                         user,
                                         children,
                                         ...props }){
    return (
      <>
          {
              _.isEmpty(user.account) || _.isNil(user.account)
              ?
                  <Card className={'d-flex justify-content-center align-items-center w-100 h-100'}>
                      <p>Please enter bank account details before you can transact on this platform</p>
                      <Link to={"/account"} className={"btn btn-primary"}>Account</Link>
                  </Card>
                  :
                  <>
                      {children}
                  </>

          }
      </>
    )
}

const mapStateToProps = state => {
    const { user } = state.auth;
    return { user };
}

export default connect(mapStateToProps)(PreventNoAccountTransaction);