import React, {
    useEffect,
    useState,
    useCallback
} from "react";
import { connect } from 'react-redux';
import { useErrorHandler } from "react-error-boundary";
import { Link } from "react-router-dom";
import _ from "lodash";

import {
    StatsWidget11,
    AdvanceTablesWidget7,
    // AdvanceCustomTables,
} from "../widgets";

import {
    getTotalTransaction,
    getChartData,
    getRecentTransaction,
} from '../../../app/modules/Transaction/_redux/TransactionCRUD';

import { actions } from '../../../app/modules/Transaction/_redux/TransactionRedux';
import { Loading } from '../../../app/modules/Components';

const mapStateToProps = state => {
    const { auth, transactionStore } = state;
    const {
        totalSales,
        totalPurchases,
        count,
    } = transactionStore.totalTransaction;

    return {
        totalSales,
        totalPurchases,
        count,
        user: auth.user,
    }
}

export default connect(mapStateToProps,actions)(function Demo4Dashboard({
                                                                user,
                                                                totalSales,
                                                                totalPurchases,
                                                                count,
                                                                FetchChartDataSuccessFulAction,
                                                                FetchTotalTransactionSuccessFulAction,
                                                                FetchRecentSaleTransactionSuccessFullAction,
                                                                FetchRecentPurchaseTransactionSuccessFull,
                                                                FetchRecentCompletedTransactionSuccessFull,
                                                                FetchRecentPendingTransactionSuccessFull,
                                                                FetchRecentCancelledTransactionSuccessFull,
                                                                ...props
                                                             }) {
    const [loading, setLoading ] = useState(true);
    const handleError = useErrorHandler();

    const fetchTransactionSummary = useCallback(async () => {
        try{
            const summary = await Promise.all([
                                getChartData(`position=buyer`),
                                getChartData(`position=seller`),
                                getChartData(`position=buyer&type=service`),
                                getChartData(`position=seller&type=service`),
                                getTotalTransaction("sales/total"),
                                getTotalTransaction("purchases/total"),
                                getTotalTransaction("count"),
                                getRecentTransaction(`recent/5/sales`),
                                getRecentTransaction(`recent/5/purchase`),
                                getRecentTransaction(`recent/5/completed`),
                                getRecentTransaction(`recent/5/pending`),
                                getRecentTransaction(`recent/5/cancelled`),
            ]);

            //dispatch to the redux store
            FetchChartDataSuccessFulAction([
                summary[0].data.data,
                summary[1].data.data,
                summary[2].data.data,
                summary[3].data.data,
            ]);

            //total overview
            FetchTotalTransactionSuccessFulAction({
                totalSales: summary[4].data.data.totalSales,
                totalPurchases: summary[5].data.data.totalPurchases,
                count: summary[6].data.data.count,
            });

            //dispatch to redux state based on the order of promise all
            //order is of high importance
            FetchRecentSaleTransactionSuccessFullAction(summary[7].data.data);
            FetchRecentPurchaseTransactionSuccessFull(summary[8].data.data);
            FetchRecentCompletedTransactionSuccessFull(summary[9].data.data);
            FetchRecentPendingTransactionSuccessFull(summary[10].data.data);
            FetchRecentCancelledTransactionSuccessFull(summary[11].data.data);
            //disable loading
            setLoading(false);
        }catch (err) {
            setLoading(false);
            //display possible error
            //and reset the component to the initial state
            handleError(err);
        }
    },[
        FetchChartDataSuccessFulAction,
        FetchTotalTransactionSuccessFulAction,
        FetchRecentSaleTransactionSuccessFullAction,
        FetchRecentPurchaseTransactionSuccessFull,
        FetchRecentCompletedTransactionSuccessFull,
        FetchRecentPendingTransactionSuccessFull,
        FetchRecentCancelledTransactionSuccessFull
    ]);

    useEffect(() => {
        fetchTransactionSummary();
    },[fetchTransactionSummary]);

    return (
        <>
            {
                loading ?
                    <Loading type={'bars'} color={'#005C6B'}/>
                    :
                    <>
                        {/* begin::Dashboard */}
                        <div className="row">
                            { (_.isEmpty(user.account) || _.isNil(user.account))
                            &&
                            <div className="col-12">
                                <div className="card-body border-top p-9">
                                    <div
                                        className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                                <span className="svg-icon svg-icon-2tx svg-icon-warning mr-4"><svg width="24"
                                                                                                   height="24"
                                                                                                   viewBox="0 0 24 24"
                                                                                                   fill="none"
                                                                                                   xmlns="http://www.w3.org/2000/svg"
                                                                                                   className="mh-50px"><rect
                                    opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect><rect
                                    x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)"
                                    fill="black"></rect><rect x="11" y="17" width="2" height="2" rx="1"
                                                              transform="rotate(-90 11 17)"
                                                              fill="black"></rect></svg></span>
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <div className="fw-bold">
                                                <h4 className="text-gray-800 fw-bolder">Notice</h4>
                                                <div className="fs-6 text-gray-600">
                                                    It is compulsory that you add your bank account details before you can transact
                                                    on this platform<br />
                                                    <Link
                                                        className="fw-bolder" to={"/account"}>Add account</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="col-md-4 mb-5 .text-white ">
                                <div className="card"
                                     style={{
                                         backgroundColor: "#005C6B",
                                         borderRadius: 15,
                                         border: "none",
                                         color: "#FFFFFF"
                                     }}
                                >
                                    <div className="card-body">
                                        <h3 style={{fontSize: 25, color: "#FFF"}}>&#8358; {totalSales}</h3>
                                        <div style={{fontSize: 18}}>Total Sales</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5 .text-white ">
                                <div className="card"
                                     style={{
                                         backgroundColor: "#ffdb9b",
                                         borderRadius: 15,
                                         border: "none",
                                     }}
                                >
                                    <div className="card-body text-left">
                                        <h3 style={{fontSize: 25}}>&#8358; {totalPurchases}</h3>
                                        <div style={{fontSize: 18}}>Total Purchases</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5 .text-white ">
                                <div className="card"
                                     style={{
                                         backgroundColor: "#FFFFFF",
                                         borderRadius: 15,
                                         border: "none",
                                     }}
                                >
                                    <div className="card-body text-left">
                                        <h3 style={{fontSize: 25}}>{count}</h3>
                                        <div style={{fontSize: 18}}>Total Transactions</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* begin::Row */}
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <StatsWidget11 />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::Row */}
                        {/* begin::Row */}
                        <div className="row" style={{marginTop: 50}}>
                            <div className="col-lg-12 col-xxl-12">
                                <AdvanceTablesWidget7 className="card-stretch gutter-b"/>
                                {/*<AdvanceCustomTables />*/}
                            </div>
                        </div>
                        {/* end::Row */}

                        {/* end::Dashboard */}
                    </>
            }
        </>
    );
})
