/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import _ from 'lodash';
import {Nav, Tab} from "react-bootstrap";
const mapStateToProps = state => {
  const { transactionStore } = state;
  return { chart: transactionStore.chart };
}

export default connect(mapStateToProps)(function StatsWidget11({
                                                      chart,
                                                      className,
                                                      ...props
}) {
    const [key, setKey] = useState("Product");
    return (
    <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Transactions
          </span>
            </h3>
            <div className="card-toolbar">
                <Tab.Container defaultActiveKey={key}>
                    <Nav
                        as="ul"
                        onSelect={(_key) => setKey(_key)}
                        className="nav nav-pills nav-pills-sm nav-dark-75"
                    >
                        <Nav.Item className="nav-item" as="li">
                            <Nav.Link
                                eventKey="Product"
                                className={`nav-link py-2 px-4 ${
                                    key === "Product" ? "active" : ""
                                }`}
                            >
                                Product
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item" as="li">
                            <Nav.Link
                                eventKey="Service"
                                className={`nav-link py-2 px-4 ${
                                    key === "Service" ? "active" : ""
                                }`}
                            >
                                Service
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Tab.Container>
            </div>
        </div>
        {/* Body */}
        <div className="card-body pt-2 pb-0 mt-n3">
            <div className={`tab-content mt-5`} id="myTabTables12">
                {/* begin::Tap pane Product */}
                <div
                    className={`tab-pane fade ${key === "Product" ? "show active" : ""}`}
                >
                    <div>
                        { !_.isEmpty(chart) &&
                        <Bar
                            data={{
                                labels: Object.keys(chart[0]),
                                datasets:[
                                    {
                                        label:'Sale',
                                        data: Object.values(chart[1]),
                                        backgroundColor:'#005C6B',
                                        barThickness:12
                                    },
                                    {
                                        label:'Purchase',
                                        data: Object.values(chart[0]),
                                        backgroundColor:'#ffdb9b',
                                        barThickness:12
                                    }
                                ]
                            }}
                            options={{
                                tooltips:{
                                    mode:'index',
                                    callbacks:{
                                        label:function(toolTipItem){
                                            return ("Revenue: $"+toolTipItem.value)
                                        }
                                    }

                                },
                                scales:{
                                    xAxes:[
                                        {
                                            gridLines:{
                                                color:'cyan'
                                            },
                                            scaleLabel:{
                                                labelString:'Months',
                                                display:true,
                                                fontColor:'blue',
                                                fontSize:20
                                            },
                                            ticks:{
                                                fontColor:'green'
                                            }
                                        }
                                    ],
                                    yAxes:[
                                        {
                                            gridLines:{
                                                color:'cyan'
                                            },
                                            scaleLabel:{
                                                labelString:'Revenue',
                                                display:true,
                                                fontColor:'blue',
                                                fontSize:20,
                                            },
                                            ticks:{
                                                beginAtZero:true,
                                                fontColor:'green',

                                            }
                                        }
                                    ]
                                }
                            }}
                        />
                        }
                    </div>

                </div>
                {/* end::Tap pane Service */}

                {/* begin::Tap pane Service */}
                <div
                    className={`tab-pane fade ${key === "Service" ? "show active" : ""}`}
                >
                    <div>
                        { !_.isEmpty(chart) &&
                        <Bar
                            data={{
                                labels: Object.keys(chart[2]),
                                datasets:[
                                    {
                                        label:'Sale',
                                        data: Object.values(chart[3]),
                                        backgroundColor:'#005C6B',
                                        barThickness:12
                                    },
                                    {
                                        label:'Purchase',
                                        data: Object.values(chart[2]),
                                        backgroundColor:'#ffdb9b',
                                        barThickness:12
                                    }
                                ]
                            }}
                            options={{
                                tooltips:{
                                    mode:'index',
                                    callbacks:{
                                        label:function(toolTipItem){
                                            return ("Revenue: $"+toolTipItem.value)
                                        }
                                    }

                                },
                                scales:{
                                    xAxes:[
                                        {
                                            gridLines:{
                                                color:'cyan'
                                            },
                                            scaleLabel:{
                                                labelString:'Months',
                                                display:true,
                                                fontColor:'blue',
                                                fontSize:20
                                            },
                                            ticks:{
                                                fontColor:'green'
                                            }
                                        }
                                    ],
                                    yAxes:[
                                        {
                                            gridLines:{
                                                color:'cyan'
                                            },
                                            scaleLabel:{
                                                labelString:'Revenue',
                                                display:true,
                                                fontColor:'blue',
                                                fontSize:20,
                                            },
                                            ticks:{
                                                beginAtZero:true,
                                                fontColor:'green',

                                            }
                                        }
                                    ]
                                }
                            }}
                        />
                        }
                    </div>
                </div>
                {/* end::Tap pane Service */}

            </div>
        </div>
    </div>
)

});
