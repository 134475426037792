import React, {useRef, useState, useCallback, useEffect} from "react";
import { useErrorHandler } from "react-error-boundary";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import * as Yup from "yup";
import _ from "lodash";


import {
    Formik,
    Form, ErrorMessage, Field,
} from 'formik';
import { connect } from "react-redux";

import {
    updateUser,
    getCloudinaryProfileUploadSignature,
    cloudinaryProfileImageUpload,
    redeemReferralPoints,
} from "../_redux/AccountCRUD";
import {
    getReferralPoint,
    actions
} from '../../Auth';

import { Button } from "../../Components";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

//css
import 'react-notifications/lib/notifications.css';

function ProfileImage({
                          user,
                          updateToken,
                          fulfillUser,
                          updateReferralPoints,
                          ...props
}){
    const [loading, setLoading] = useState(false);
    const [redeemBtnLoading, setRedeemBtnLoading] = useState(false);
    const handleError = useErrorHandler();
    const { REACT_APP_REDEMPTION_FACTOR } = process.env;
    const FILE_SIZE = 3072 * 1024;
    const photoRef = useRef();
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/png"
    ];

    const fetchReferralPoint = useCallback(() => {
        getReferralPoint().then(response => {
            updateReferralPoints(response.data.data.referralPoints);
        },handleError);
    },[getReferralPoint])

    useEffect(() => {
        fetchReferralPoint();
    },[fetchReferralPoint]);

    const ImageSchema = Yup.object().shape({
        photo: Yup.mixed()
            .required("The profile image is required")
            .test(
                "fileFormat",
                "Unsupported Image Format",
                value => value && SUPPORTED_FORMATS.includes(value.type)
            )
            .test(
                "fileSize",
                "File is too large",
                value => value && value.size <= FILE_SIZE
            )
    });

    const RedeemSchema = Yup.object().shape({
        points: Yup.number().required("Redeem point is required")
            .test(
                "multiples",
                "Point(s) should be a multiple of 5",
                value => {
                    return Number(value) !== 0 && Number(value) % Number(REACT_APP_REDEMPTION_FACTOR) === 0;
                }
            )
            .test(
                "limit",
                `You can only reedem ${user.referralPoints} point(s)`,
                value => Number(value) <= Number(user.referralPoints)
            )
    });

    const handleRedeemSubmit = async (values) => {
        try {
            const { points } = values;
            setRedeemBtnLoading(true);
            const response = (await redeemReferralPoints(points)).data;
            const {data, token } = response;
            updateToken(token);
            updateReferralPoints(data.referralPoints);
            setRedeemBtnLoading(false);
            NotificationManager.success('Redemption Successful', 'Success');
        }catch (e) {
            setRedeemBtnLoading(false);
            NotificationManager.error(`${e.response.data.message}`,'Error');
        }
    }

    return (
        <>
            <div className="card mb-5 mb-xxl-8">
                <div className="card-body pt-9 pb-0">
                    {/*begin::Details*/}
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        {/*begin: Pic*/}
                        <div className="me-7 mb-4">
                            <div>
                                {
                                    _.isEmpty(user.photo)
                                    ?
                                        <img src={toAbsoluteUrl('/media/avatar/avatar.png')} style={{width:"200px",height:"200px", borderRadius: "10px"}} alt="profile-image"/>
                                    :
                                        <img src={user.photo} alt="image"  style={{width:"200px",height:"200px"}}/>
                                }
                                <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px" />
                            </div>
                        </div>
                        {/*end::Pic*/}
                        {/*begin::Info*/}
                        <div className="flex-grow-1">
                            {/*begin::Title*/}
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                {/*begin::User*/}
                                <div className="d-flex flex-column">
                                    {/*begin::Name*/}
                                    <div className="d-flex align-items-center mb-2">
                                        <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{user.username}</a>
                                        <a href="#">
                                            {/*begin::Svg Icon | path: icons/duotune/general/gen026.svg style={{backgroundColor:"#F7B319"}}*/}
                                            <span className=" svg-icon-1 " >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                                  <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#F7B319" />
                                                  <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                                                </svg>
                                            </span>
                                            {/*end::Svg Icon*/}
                                        </a>
                                    </div>
                                    {/*end::Name*/}
                                </div>
                                {/*end::User*/}
                            </div>
                            {/*end::Title*/}
                            {/*begin::Stats*/}
                            <div className="d-flex flex-wrap flex-stack">
                                {/* begin profile image upload */}
                                {/*begin::Form*/}
                                <Formik
                                    validationSchema={ImageSchema}
                                    initialValues={{
                                        photo: undefined
                                    }}
                                    onSubmit={ values => {
                                        const { photo } = values;
                                        //spin submit button
                                        setLoading(true);
                                        getCloudinaryProfileUploadSignature().then(res => {
                                            const { cloudname } = res.data.data;
                                            const cloudinary_upload_url = `https://api.cloudinary.com/v1_1/${cloudname}/auto/upload`;
                                            cloudinaryProfileImageUpload(cloudinary_upload_url,photo,res.data.data).then(photo => {
                                                //finally update profile image
                                                updateUser({ photo }).then(response => {
                                                    const { data, token } = response.data;
                                                    updateToken(token);
                                                    fulfillUser(data);
                                                    //disable button
                                                    setLoading(false);
                                                });
                                            })
                                        }).catch(err => {
                                        //disable loading button
                                        setLoading(false)
                                        //probably flash some react notif over here
                                        handleError(err);
                                        console.log(err);
                                        });
                                    }}
                                >
                                    {({errors,values, touched, setFieldValue}) => (
                                        <Form>
                                            {/* begin::Field */}
                                            <input
                                                style={{marginBottom:10}}
                                                name={"photo"}
                                                type={"file"}
                                                className="form-control form-control-solid"
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    let reader = new FileReader();
                                                    const file = event.target.files[0];
                                                    if(file){
                                                        reader.readAsDataURL(file);
                                                        setFieldValue("photo",file)
                                                    }
                                                }}
                                                ref={photoRef}
                                            />
                                            {/* end::Field*/}
                                            {/* end::Field*/}
                                            <ErrorMessage
                                                name={`photo`}
                                                component="div"
                                                className="field-error is-invalid"
                                            />
                                            <Button loading={loading} />
                                        </Form>
                                        )}
                                </Formik>
                                {/* end begin profile upload */}
                                {/*begin::Wrapper*/}
                                {/* className="d-flex flex-column flex-grow-1 pe-8"  */}
                                <div  style={{marginTop:10}}>
                                    {/*begin::Stats*/}
                                    <div className="d-flex flex-wrap">
                                        {/*begin::Stat*/}
                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            {/*begin::Number*/}
                                            <div className="d-flex align-items-center">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                                <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x={13} y={6} width={13} height={2} rx={1} transform="rotate(90 13 6)" fill="black" />
                                                    <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black" />
                                                  </svg>
                                                </span>
                                        {/*end::Svg Icon*/}
                                        <div className="fs-2 fw-bolder counted"
                                             data-kt-countup="true"
                                             data-kt-countup-value={user.transactionPoints}>{user.transactionPoints}
                                        </div>
                                        </div>
                                                {/*end::Number*/}
                                                {/*begin::Label*/}
                                                <div className="fw-bold fs-6 text-gray-400">Transaction Point(s)</div>
                                                {/*end::Label*/}
                                        </div>
                                        {/*end::Stat*/}
                                 </div>
                                    {/*end::Stats*/}

                                    {/*begin::Stats*/}
                                    <div className="d-flex flex-wrap">
                                        {/*begin::Stat*/}
                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            {/*begin::Number*/}
                                            <div className="d-flex align-items-center">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                                                <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x={13} y={6} width={13} height={2} rx={1} transform="rotate(90 13 6)" fill="black" />
                                                    <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black" />
                                                  </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                                <div className="fs-2 fw-bolder counted"
                                                     data-kt-countup="true"
                                                     data-kt-countup-value={user.referralPoints}>{user.referralPoints}
                                                </div>
                                            </div>
                                            {/*end::Number*/}
                                            {/*begin::Label*/}
                                            <div className="fw-bold fs-6 text-gray-400">Referral Credit(s)</div>
                                            {/*end::Label*/}
                                        </div>
                                        {/*end::Stat*/}
                                    </div>
                                    {/*end::Stats*/}
                                    {
                                        (!_.isEmpty(user.account) || !_.isNil(user.account))
                                        &&
                                            <>
                                            <Formik
                                                validationSchema={RedeemSchema}
                                                initialValues={{
                                                    points: ""
                                                }}
                                                onSubmit={handleRedeemSubmit}
                                            >
                                                {({errors,values, touched, setFieldValue}) => (
                                                    <Form>
                                                        <Field
                                                            name={"points"}
                                                            id={"points"}
                                                            type={"number"}
                                                            min={Number(REACT_APP_REDEMPTION_FACTOR)}
                                                            className="form-control form-control-solid mb-4"
                                                            placeholder={"e.g. 5"}
                                                        />
                                                        <ErrorMessage
                                                            name={`points`}
                                                            component="div"
                                                            className="field-error is-invalid mb-2"
                                                            style={{color:"red"}}
                                                        />
                                                        <Button
                                                            loading={redeemBtnLoading}
                                                            text={"Redeem Referral Credit(s)"}
                                                        />
                                                    </Form>
                                                )}
                                            </Formik>
                                            </>
                                    }
                                </div>
                                {/*end::Wrapper*/}

                            </div>
                            {/*end::Stats*/}
                        </div>
                        {/*end::Info*/}
                    </div>
                    {/*end::Details style={{color:"#F7B319"}}*/}
                    {/*begin::Navs*/}
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder" >
                        {/*begin::Nav item text-active-primary*/}
                        <li className="nav-item mt-2">
                            <a className="nav-link  ms-0 me-10 py-5 active" href="#" style={{color:"#F7B319"}}>Overview</a>
                        </li>
                    </ul>
                    {/*begin::Navs*/}
                </div>
            </div>
            <NotificationContainer/>
        </>
    )
}

const mapStateToProps = state => {
    const { user } = state.auth;
    return { user }
}

export default connect(mapStateToProps, actions)(ProfileImage);
