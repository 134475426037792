import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import {shallowEqual, useSelector} from "react-redux";

export function Topbar() {
  const uiService = useHtmlClassService();
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const layoutProps = useMemo(() => {
    return {
      newTransactionDisplay:objectPath.get(
          uiService.config,
          "extras.transaction.display"
      ),
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div className="topbar">

      {layoutProps.viewSearchDisplay && <SearchDropdown />}

      {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}

      {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}

      {layoutProps.viewCartDisplay && <MyCartDropdown />}

      {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}

      {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}

      {/* temporary QuickUserToggler */}
      {/*<div className="topbar-item">*/}
      {/*  <div*/}
      {/*      className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 mr-2"*/}
      {/*  >*/}
      {/*    <>*/}
      {/*          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">*/}
      {/*            Hi,*/}
      {/*          </span>*/}
      {/*          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">*/}
      {/*            {user.username}*/}
      {/*          </span>*/}
      {/*    </>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* end temporary QuickUserToggler*/}

      {layoutProps.newTransactionDisplay &&
      <div className="topbar-item"  >
        <Link
            to={"/new-transaction"}
            className={"btn  mr-2"}
            style={{backgroundColor:'#005c6b', color:'#FFFFFF'}}
        >New Transaction</Link>
      </div>
      }

    </div>
  );
}
