import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import {format} from "date-fns";
import { useErrorHandler } from "react-error-boundary";

import { processFlutterwavePayment } from "../_redux/TransactionCRUD";
import logo from '../../../../_metronic/logo/vahlid_logo.jpg';
import { getTransactionById } from "../_redux/TransactionCRUD";
import { actions } from "../_redux/TransactionRedux";
import { PreLoader, Button } from "../../Components";

function Invoice({
                     history,
                     user,
                     transaction,
                     FetchTransactionSuccessFull,
                     ...props
                 }){


    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const handleError = useErrorHandler();
    const { trans_id } = useParams();

    const prepareInvoice = useCallback(async () => {
        // prevent memory leakage
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        try {
            const response = await getTransactionById({ trans_id, source });
            FetchTransactionSuccessFull(response.data.data);
            setPageLoading(false);
        }catch (e) {
            setPageLoading(false);
            source.cancel("Request Cancelled"); //check this out later
            handleError(e);
        }
    },[]);

    useEffect(() => {
        prepareInvoice();
    },[prepareInvoice]);

    const {
        delivery_addr,
        created_at,
        products,
        seller,
        buyer,
        fee,
        products_price,
        is_deposit_made,
    } = transaction;
    
    const grand_total = products_price + fee;

    const handleFlutterwavePayment = () => {
        //spin button
        setLoading(true);
        //compute the type parameter based on the currently authenticated user
        //and the transaction details
        let type = user._id === seller._id ? "sell" : "buy";
        processFlutterwavePayment(transaction, type).then(res => {
            if (res.data.data.link){
                window.location.href = res.data.data.link;
            }else{
                //give users the option to try again
                setLoading(false);
            }
        }).catch(err => {
            //you might want to use some toast notification
            setLoading(false);
            console.log(err)
        });
    }

    return (<>
            <PreLoader
              type={"spinningBubbles"}
              color={"#005C6B"}
              loading={pageLoading}
            >
                <div className="card card-custom overflow-hidden">
                    <div className="card-body p-0">
                        <div className="row justify-content-center py-8 px-8 py-md-27 px-md-0">
                            <div className="col-md-9">
                                <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                    <h1 className="display-4 font-weight-boldest mb-10">INVOICE</h1>
                                    <div className="d-flex flex-column align-items-md-end px-0">
                                        <a href="#" className="mb-5">
                                            <img src={logo} alt="logo-img" style={{width:60}}/>
                                        </a>
                                        <span className="d-flex flex-column align-items-md-end opacity-70">
                                            <span className="text-bold-800">Vahlid Ltd.</span>
                                            <span>5, Olufunmilola Okikiolu Street,</span>
                                            <span>Off Toyin Street, Ikeja</span>
                                            <span>Nigeria</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="border-bottom w-100"/>
                                <div className="d-flex justify-content-between pt-6">
                                    <div className="d-flex flex-column flex-root">
                                        <span className="font-weight-bolder mb-2">Date</span>
                                        <span className="opacity-70">{format(new Date(created_at), 'MMMM dd, yyyy')}</span>
                                    </div>
                                    <div className="d-flex flex-column flex-root">
                                        <span className="font-weight-bolder mb-2">INVOICE NO.</span>
                                        <span className="opacity-70">#{trans_id}</span>
                                    </div>
                                    <div className="d-flex flex-column flex-root">
                                        <span className="font-weight-bolder mb-2">INVOICE TO.</span>
                                        {user._id === seller._id ? <>
                                                <div>Email : <span className="opacity-70">{seller.email}</span></div>
                                                <div>Vahlid ID : <span className="opacity-70">{seller.vahlid_id}</span></div>
                                                <div>Delivery Address : <span className="opacity-70">{delivery_addr}</span></div>
                                            </>
                                            :
                                            <>
                                                <div>Email : <span className="opacity-70">{buyer.email}</span></div>
                                                <div>Vahlid ID : <span className="opacity-70">{buyer.vahlid_id}</span></div>
                                                <div>Delivery Address : <span className="opacity-70">{delivery_addr}</span></div>
                                            </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                            <div className="col-md-9">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th className="pl-0 font-weight-bold text-muted text-uppercase">Name</th>
                                            <th className="font-weight-bold text-muted text-uppercase">Description</th>
                                            <th className="pr-0 font-weight-bold text-muted text-uppercase">Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {products.map((product,index) => (
                                            <tr className="font-weight-boldest" key={index}>
                                                <td className="pl-0 pt-7">{product.name}</td>
                                                <td className="pt-7">{product.prod_desc.length > 50 ? product.prod_desc.substr(0,50) + "..." : product.prod_desc}</td>
                                                <td className="text-danger  pt-7">
                                                    {product.prod_price && <>&#8358;<CurrencyFormat value={product.prod_price} displayType={'text'} thousandSeparator={true} /></>}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                            <div className="col-md-9">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className="font-weight-bold text-muted text-uppercase">SUBTOTAL</td>
                                            <td className="text-danger text-center font-size-h5 font-weight-boldest">
                                                {products_price && <>&#8358;<CurrencyFormat value={products_price} displayType={'text'} thousandSeparator={true} /></>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold text-muted text-uppercase">Vahlid Charges</td>
                                            <td className="text-danger text-center font-size-h5 font-weight-boldest">
                                                {fee && <>&#8358;<CurrencyFormat value={fee} displayType={'text'} thousandSeparator={true} /></>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold text-muted text-uppercase">Grand Total</td>
                                            <td className="text-danger text-center font-size-h5 font-weight-boldest">
                                                { grand_total ?? <>&#8358;<CurrencyFormat value={grand_total} displayType={'text'} thousandSeparator={true} /></>}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>



                        <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                            <div className="col-md-9">
                                <div className="d-flex justify-content-between">
                                    <div/>
                                    {/*<button type="button" className="btn btn-light-primary font-weight-bold">Download Invoice*/}
                                    {/*</button>*/}
                                    { !is_deposit_made ? <Button type="button"
                                                                 className="btn btn btn-warning font-weight-bold font-weight-bold"
                                                                 onClick={handleFlutterwavePayment}
                                                                 loading={loading}
                                                                 text={"Make Payment"}
                                    /> : <div className={"text-info"}>Paid</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PreLoader>
        </>)
}

const mapStateToProps = state => {
    const { transaction } = state.transactionStore;
    const { user } = state.auth;
    return { transaction, user };
}

export default connect(mapStateToProps,actions)(Invoice);




