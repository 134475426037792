import React, { useState, useEffect } from 'react';
import {  connect,useSelector} from 'react-redux';
import { resendVerificationMail } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";


function VerifyAccount(props){
    const [loading, setLoading] = useState(false);
    const [ notif, setNotif ] = useState("Please check your mail for the verification link.");
    const user = useSelector(state => state.auth.user);
    const { is_verified } = user;
    useEffect(() => {
        if(!is_verified){
            props.clearToken();
        }
    },[is_verified]);
    const handleResend = () => {
        const { _id } = user;
        //spin button
        setLoading(true);
        resendVerificationMail(_id).then(res => {
           setNotif("Check your email for account verification mail");
           props.clearToken();
           //stop button spin
           setLoading(false);
        }).catch(err => {
            //stop button spin
            setLoading(false);
            setNotif("Didn't receive verification mail?")
        });
    }
    if (!user.is_verified){
        return (
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw'}}>
                <p>{notif}</p>
                <button
                    type="button"
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3 d-block`}
                    onClick={handleResend}
                    disabled={loading}
                >
                    <span >Resend Verification mail</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
            </div>
        )
    }else{
        return (<>{props.children}</>)
    }
};


export default connect(null,auth.actions)(VerifyAccount);