import React from "react";
import {Field, ErrorMessage } from "formik";



function ServiceField({
                          products,
                          remove,
                          index,
                          errors,
                          touched,
                          ...props
    }){
    return (
        <>
            <hr/>
            <div className="d-flex align-self-end">
                { index > 0 ? (
                    <button type={"button"} onClick={() => remove(index)}>
                        X
                    </button>
                ) : ''}
            </div>
            <div className="row mb-5" style={{paddingRight:25, paddingLeft:25, paddingTop:10, paddingBottom:10}}>
                {/*begin::Col*/}
                <div className="col-md-6 fv-row">
                    {/*begin::Label*/}
                    <label htmlFor={`products.${index}.name`} className="required fs-5 fw-bold mb-2" style={{paddingBottom:10}}>Service Name </label>
                    {/*end::Label*/}
                    {/* begin::Field */}
                    <Field name={`${products}.${index}.name`} className="form-control form-control-solid" placeholder={"Laundry"} />
                    <ErrorMessage
                        name={`products.${index}.name`}
                    >
                        {msg => <div style={{color: "red"}}>{msg}</div>}
                    </ErrorMessage>
                    {/* end::Field*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className="col-md-6 fv-row">
                    {/*end::Label*/}
                    <label htmlFor={`${products}.${index}.prod_price`} className="required fs-5 fw-bold mb-2" style={{paddingBottom:10}}>Service Price </label>
                    {/*end::Label*/}
                    {/* begin::Field */}
                    <Field name={`${products}.${index}.prod_price`} className="form-control form-control-solid" placeholder={"e.g 2000"} />
                    {/* end::Field*/}
                    <ErrorMessage
                        name={`${products}.${index}.prod_price`}
                    >
                        {msg => <div style={{color: "red"}}>{msg}</div>}
                    </ErrorMessage>
                </div>
                {/*end::Col*/}

                {/*begin::Col*/}
                <div className="col-md-6 fv-row" style={{paddingBottom:10, paddingTop:20}}>
                    {/*end::Label*/}
                    <label htmlFor={`${products}.${index}.prod_desc`} className="required fs-5 fw-bold mb-2" style={{paddingBottom:10}}>Service Description </label>
                    {/*end::Label*/}
                    {/* begin::Field */}
                    <Field as={'textarea'} rows="4" name={`${products}.${index}.prod_desc`} className="form-control form-control-solid" placeholder={"e.g Fairly Used"} />
                    {/* end::Field*/}
                    <ErrorMessage
                        name={`${products}.${index}.prod_desc`}
                    >
                        {msg => <div style={{color: "red"}}>{msg}</div>}
                    </ErrorMessage>
                </div>
                {/*end::Col*/}
            </div>
        </>
    );
}


export default ServiceField;
