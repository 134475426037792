/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
// https://github.com/conorhastings/react-syntax-highlighter#prism
// See https://github.com/PrismJS/prism-themes
import { useHistory } from 'react-router-dom';
import {
    Formik,
    Field,
    Form,
    ErrorMessage
} from 'formik';
import { connect } from 'react-redux';
import * as Yup from "yup";
import axios from 'axios';
import { useErrorHandler } from "react-error-boundary";

import { Button } from "../../Components";
import { getTransactionByIdForDispute } from "../_redux/DisputeCRUD";
import { Loading } from "../../Components";
import { createDispute } from '../_redux/DisputeCRUD';
import { actions } from "../../Transaction/_redux/TransactionRedux";
import { actions as disputeActions } from '../_redux/DisputeRedux';

function DisputeForm({
                         trans_id,
                         user,
                         transaction,
                         FetchTransactionSuccessFull,
                         DisputeCreatedAction,
                         ...props
}) {
    const [ loading, setLoading ] = useState(true);
    const [ btnLoading, setBtnLoading ] = useState(false);

    const handleError = useErrorHandler();
    const history = useHistory();

    const DisputeSchema = Yup.object().shape({
       title: Yup.string().required("A dispute must have a title"),
       description: Yup.string().required("A dispute must have a description")
    });

    useEffect(() => {
       // prevent memory leakage
       const cancelToken = axios.CancelToken;
       const source = cancelToken.source();
       getTransactionByIdForDispute({trans_id, source}).then(res => {
           FetchTransactionSuccessFull(res.data.data);
           setLoading(false);
       }).catch(err => {
           //flash some react notif
           setLoading(false);
           handleError(err);
       });
       return () => source.cancel("Request Cancelled");
    },[trans_id]);

    return (
        <>
            { loading
                ?
                <Loading type={'spinningBubbles'} color={'#005C6B'}/>
                :
                <div className="card card-custom">
                    <div className="card-header">
                        <h3 className="card-title">
                            Dispute
                        </h3>
                    </div>
                    <div className="card-body">
                        <p>Welcome to dispute section.</p>
                        <Formik
                            validationSchema={DisputeSchema}
                            initialValues={{
                                title: "",
                                description: ""
                            }}
                            onSubmit={async (values) => {
                                try {
                                    const { title, description } = values;
                                    const { _id, trans_id, seller } = transaction;
                                    const details = {
                                        transaction: _id,
                                        title,
                                        description,
                                        reported_user: seller._id,
                                    };
                                    setBtnLoading(true);
                                    const res = await createDispute(details);
                                    const { data } = res.data;
                                    //dispatch to state
                                    DisputeCreatedAction(data);
                                    setBtnLoading(false);
                                    history.push(`/dispute/${trans_id}/${data.dispute_id}/session`);

                                    console.log(res);
                                }catch (e) {
                                    console.log(e.message);
                                    setBtnLoading(false);
                                }
                            }}
                        >
                            {({errors,values, touched, setFieldValue}) => (
                                <Form>
                                    {/*begin::Input group*/}
                                    <div className="row mb-5">
                                        {/* begin:General Details for all products */}
                                        <div className="col-12 fv-row">
                                            {/*begin::Label*/}
                                            <label className="required fs-5 fw-bold my-2">Title *</label>
                                            {/*end::Label*/}
                                            <Field name={"title"} id={"title"} className="form-control form-control-solid" placeholder={"Enter title"} />
                                            {errors.title && touched.title && <div>{errors.title}</div>}
                                        </div>
                                        <div className="col-12 fv-row">
                                            {/*begin::Label*/}
                                            <label className="required fs-5 fw-bold my-2">Description *</label>
                                            {/*end::Label*/}
                                            <Field name={"description"} as={"textarea"} rows={10} className="form-control form-control-solid" />
                                            {errors.description && touched.description && <div>{errors.description}</div>}
                                        </div>
                                        {/* end:General Details for all products */}
                                    </div>
                                    {/*end::Input group*/}
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            loading={btnLoading}
                                            className="btn btn-primary mr-2"
                                            text={"Proceed"}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            }
        </>
    );
}

const mapStateToProps = state => {
    const { transaction } = state.transactionStore;
    const { user } = state.auth;
    return { user, transaction }
}

export default connect(mapStateToProps, {
    ...actions,
    ...disputeActions
})(DisputeForm);
