import React from 'react';
import './InputField.css';
const classNames = require('classnames');

function InputField(props){
    const {
        placeholder,
        defaultValue,
        onChange,
        value,
        rightButtons,
    } = props;

    return (
           <div className={classNames('rce-container-input')}>
               <input
                      className={classNames('rce-input')}
                      type="text"
                      placeholder={placeholder}
                      onChange={onChange}
                      value={value}
               />
               <div className={classNames('rce-input-buttons')}>
                   {rightButtons}
               </div>
           </div>
    );
}

InputField.defaultProps = {
    type: 'text',
    placeholder: '',
    onChange: null,
    rightButtons: null,
    minHeight: 25,
    maxHeight: 200,
    autoHeight: true,
    inputRef: null,
    maxlength: null,
    onMaxLengthExceed: null,
    autofocus: false,
};

export default InputField;