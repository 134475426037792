import React from 'react';
import { Card } from "react-bootstrap";
import { connect } from 'react-redux';
import { default as FeedbackDetails } from "./FeedbackDetails";


function TransactionFeedback({ transaction,...props }){
   const { _id } = props.auth.user;
   const {
       status,
       is_delivery_made,
       is_deposit_made,
       seller,
       products_price,
       pay_deadline,
       trans_id,
       is_delivery_confirmed,
   } = transaction;

   return(<>
       <Card style={{height : "50%"}}>
           <Card.Body>
               <Card.Title>Transaction Status</Card.Title>
               <FeedbackDetails
                   _id={_id}
                   status={status}
                   is_delivery_made={is_delivery_made}
                   is_deposit_made={is_deposit_made}
                   is_delivery_confirmed={is_delivery_confirmed}
                   seller={seller}
                   products_price={products_price}
                   pay_deadline={pay_deadline}
                   trans_id={trans_id}
               />
           </Card.Body>
       </Card>
   </>)
}

const mapStateToProps = state => {
    const { auth } = state;
    return { auth }
}

export default connect(mapStateToProps)(TransactionFeedback);