/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function ListsWidget8({ className }) {
  return (
      <>
        <div className="row">
          <div className="col-md-4 mb-5 .text-white ">
            <div className="card card-custom btn btn-primary .text-white">
              <div className="card-header">
                <div className="card-title">
            <span className="card-icon">
                <i className="flaticon2-chat-1 text-primary"></i>
            </span>
                  <h3 className="card-label">
                    N724,000.00
                  </h3>
                </div>

              </div>
              <div className="card-body">
                <div data-scroll="true" data-height="200">
                  Total Sales
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-custom btn btn-warning">
              <div className="card-header">
                <div className="card-title">
            <span className="card-icon">
                <i className="flaticon2-chat-1 text-primary"></i>
            </span>
                  <h3 className="card-label">
                    N124,000.00
                  </h3>
                </div>

              </div>
              <div className="card-body">
                <div data-scroll="true" data-height="200">
                  Total Purchases
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-custom btn btn-default">
              <div className="card-header">
                <div className="card-title">
            <span className="card-icon">
                <i className="flaticon2-chat-1 text-primary"></i>
            </span>
                  <h3 className="card-label">
                    12
                  </h3>
                </div>

              </div>
              <div className="card-body">
                <div data-scroll="true" data-height="200">
                  Total Transactions
                </div>
              </div>

            </div>
          </div>
        </div>
      </>
  );
}
