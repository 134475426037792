import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { generateNewAPIKeys } from "../_redux/DeveloperAPI";
import { actions } from "../../Auth";

//component
import { Button } from "../../Components";

//css
import "../../../../_metronic/_assets/css/style.bundle.css";
import "../../../../_metronic/_assets/css/handy.css";

function APIKey({ user, setUser, fulfillUser, updateToken, ...props }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    const generateKeyResponse = await generateNewAPIKeys();
    const { token, data } = generateKeyResponse;
    updateToken(token);
    fulfillUser(data);
    setLoading(false);
  }

  const copyAPIKey = () => {
    navigator.clipboard.writeText(user.api_key);
  }

  const formik = useFormik({
    initialValues: user,
    onSubmit: useCallback(handleSubmit, [handleSubmit])
  });

  const defaultAPIKEyValue = "**********************************************************";

  return (
      <>
        <div className="card mb-5 mb-xxl-10">
          <div className="card-header">
            <div className="card-title">
              <h3>API Overview</h3>
            </div>
          </div>
          <div className="card-body py-10">
            <div className="row mb-10">
              <div className="col pb-10 pb-lg-0">
                <h2>How to set up API</h2>
                <p className="fs-6 fw-bold text-gray-600 py-2">
                  The Vahlid Developer API allows developers to incorporate safe,
                  scam-free payments into their platforms.
                </p>
                <a
                    href="https://vahlid.gitbook.io/vahlid-developer-api"
                    className="btn btn-light btn-active-light-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header card-header-stretch">
            <div className="card-title">
              <h3>API Keys</h3>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="table-responsive">
              <table
                  className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                  id="kt_api_keys_table"
              >
                <tbody className="fs-6 fw-bold text-gray-600">
                <tr>
                  <td className="ps-9">API Key</td>
                  <td data-bs-target="license" id="api_key" className="ps-0">
                    <div className="api-input-wrapper">
                      <input
                          className="form-control"
                          value={defaultAPIKEyValue}
                          type="password"
                          name="api_key"
                          id="api_key"
                          readOnly
                      />
                      <button
                          data-action="copy"
                          className="btn btn-active-color-primary btn-icon btn-sm btn-outline-light btn-copy"
                          onClick={copyAPIKey}
                      >
                        <span className="svg-icon svg-icon-2">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                          >
                            <path
                                opacity="0.5"
                                d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                fill="black"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                fill="black"
                            />
                          </svg>
                        </span>
                      </button>
                      <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/>
                        <circle cx="12" cy="12" r="3"/>
                      </svg>
                    </div>
                  </td>
                  <td/>
                </tr>
                </tbody>
              </table>
            </div>


            <div className="mb-4 d-flex justify-content-center" >
              <Button
                  loading={loading}
                  type={"button"}
                  text={"Generate New API Keys"}
                  onClick={formik.handleSubmit}
              />
            </div>

          </div>
        </div>
      </>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.auth;
  return { user };
};

export default connect(mapStateToProps, actions)(APIKey);
