import React, { useEffect, useState,useCallback } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

import { actions } from "../_redux/DisputeRedux";

import {
    getAllDisputeForSingleUser
} from "../_redux/DisputeCRUD";

import { PreLoader, EmptyComponent } from "../../Components";

function UserDisputeList({
                         user,
                         disputeStore,
                         ClearDisputesSuccessfulAction,
                         FetchDisputesSuccessfulAction,
                         FetchMoreDisputeSuccessfulAction,
                         ...props
}){
    const handleError = useErrorHandler();
    const { count, data } = disputeStore.disputes;
    const [loader, setLoader] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 2;
    const [totalPages, setTotalPages] = useState(0);

    const fetchSingleUserDisputes = useCallback(() => {
        //clear previous records incase of refresh
        ClearDisputesSuccessfulAction();
        //also reset the current page to the initial value
        setCurrentPage(1);
        getAllDisputeForSingleUser({
            userId: user._id,
            query: `page=${currentPage}&limit=${limit}`
        }).then(
            response => {
                //update the total number of pages on
                //first fetch
                setTotalPages(Math.round(response.data.count / limit));
                FetchDisputesSuccessfulAction(response.data)
                //disable preloader
                setLoader(false);
            },
            handleError
        );
    },[getAllDisputeForSingleUser]);

    const fetchMoreSingleUserDisputes = () => {
        if (currentPage <= totalPages){
            const nextPage = currentPage + 1;
            //always get the next 10 records
            getAllDisputeForSingleUser({
                userId: user._id,
                query: `page=${nextPage}&limit=${limit}`
            }).then(
                response => {
                    FetchMoreDisputeSuccessfulAction(response.data);
                    //update the page for the next scroll
                    setCurrentPage(nextPage);
                },
                handleError
            );
        }
    }


    useEffect(() => {
        fetchSingleUserDisputes();
    },[fetchSingleUserDisputes]);

    return (
        <PreLoader
            loading={loader}
            type={"spinningBubbles"}
            color={"#005C6B"}
        >
            <EmptyComponent
                message={"You are yet to raise a dispute"}
                data={data}
            >
                <InfiniteScroll
                    dataLength={data.length} //This is important field to render the next data
                    next={fetchMoreSingleUserDisputes}
                    hasMore={data.length < count}
                    loader={<h6>Loading...</h6>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                    // below props only if you need pull down functionality
                    refreshFunction={fetchSingleUserDisputes}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                        <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                    }
                    releaseToRefreshContent={
                        <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                    }
                >
                    {
                        data.map((dispute, index) => (
                            <Card key={index} className={"mb-4"}>
                                <Card.Body>
                                    <Card.Title>Title: {dispute.title}</Card.Title>
                                    <Card.Text><strong>Description:</strong> {dispute.description.substring(0,100)}...</Card.Text>
                                    <Card.Text><strong>Reported By:</strong> {dispute.reported_by.username}</Card.Text>
                                    <Card.Text><strong>Reported User:</strong>{dispute.reported_user.username}</Card.Text>
                                    <Card.Text><strong>Assigned Agent:</strong> Coming Soon</Card.Text>
                                    <Card.Text><strong>Status:</strong> {dispute.status}</Card.Text>
                                    <p><Link
                                        to={`/dispute/${dispute.transaction.trans_id}/${dispute.dispute_id}/session`}
                                        className={"btn btn-primary"}> View </Link>
                                    </p>
                                </Card.Body>
                            </Card>
                        ))
                    }
                </InfiniteScroll>
            </EmptyComponent>
        </PreLoader>
    );
}

export default connect(state => {
    const { disputeStore, auth } = state;
    return { disputeStore, user: auth.user }
},actions)(UserDisputeList);