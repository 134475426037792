import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const actionTypes = {
    DisputeCreated: "[DisputeCreated] Action",
    FetchDisputeSuccessful: "[FetchDisputeSuccessful] Action",
    FetchDisputesSuccessful: "[FetchDisputesSuccessful] Action",
    FetchMoreDisputeSuccessful: "[FetchMoreDisputeSuccessful] Action",
    ClearDisputesSuccessful: "[ClearDisputesSuccessful] Action",
    FetchDisputeMessagesSuccessful: "[FetchDisputeMessagesSuccessful] Action",
    CreateDisputeMessageSuccessful: "[CreateDisputeMessageSuccessful] Action",
    SetMessageTotalPagesSuccessFul: "[SetMessageTotalPagesSuccessFul] Action",
    ClearDisputeMessagesSuccessful: "[ClearDisputeMessagesSuccessful] Action",
    FetchMoreDisputeMessagesSuccessful: "[FetchMoreDisputeMessagesSuccessful] Action",
}

const initialDisputeState = {
    dispute: {
        title: "",
        description: "",
        reported_by: {
            username: "",
        },
        reported_user: {
            username: "",
        },
        count: 0,
        messageTotalPages: 0,
        status: "pending",
        messages: [],
    }, //by default
    disputes: {
        count: 0,
        data: [],
    }
}

export const DisputeReducer = persistReducer({
    key: 'vahlid-dispute',
    storage
},(state = initialDisputeState, action) => {
    switch (action.type){
        case actionTypes.ClearDisputeMessagesSuccessful: {
           return {
               ...state,
               dispute: {
                   count: 0,
                   messageTotalPages: 0,
                   status: "pending",
                   messages: [],
               }
           }
        }
        case actionTypes.SetMessageTotalPagesSuccessFul: {
          const { count, messageTotalPages } = action.payload;
          return { ...state,
            dispute: {
              ...state.dispute,
                count,
                messageTotalPages,
            }
          }
        }
        case actionTypes.CreateDisputeMessageSuccessful: {
            return {
                ...state,
                dispute: {
                  ...state.dispute,
                  messages: [ action.payload, ...state.dispute.messages ]
                },
            }
        }
        case actionTypes.FetchDisputeMessagesSuccessful:{
            return {
                ...state,
                dispute: {
                    ...state.dispute,
                    messages: action.payload,
                }
            }
        }
        case actionTypes.FetchMoreDisputeMessagesSuccessful: {
            return {
                ...state,
                dispute: {
                    ...state.dispute,
                    messages: [...state.dispute.messages, ...action.payload]
                }
            }
        }
        case actionTypes.DisputeCreated:{
            return {
                ...state, dispute: {
                    ...state.dispute,
                    ...action.payload,
                }
            }
        }
        case actionTypes.FetchDisputeSuccessful:{
            return {
                ...state,
                dispute: {
                    ...state.dispute,
                    ...action.payload,
                }
            }
        }
        case actionTypes.FetchDisputesSuccessful: {
            const { count, data } = action.payload;
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    count,
                    data,
                }
            }
        }
        case actionTypes.FetchMoreDisputeSuccessful: {
            const { data } = action.payload;
            return {
                ...state,
                disputes: {
                    ...state.disputes,
                    data: [
                        ...state.disputes.data,
                        ...data,
                    ]
                }
            }
        }
        case actionTypes.ClearDisputesSuccessful: {
            return {
                ...state,
                disputes: {
                    count: 0,
                    data: [],
                }
            }
        }
        default:
            return state
    }
});

export const actions = {
  DisputeCreatedAction: (dispute) => ({ type: actionTypes.DisputeCreated, payload: dispute }),
  FetchDisputeSuccessfulAction: dispute => ({ type: actionTypes.FetchDisputeSuccessful, payload: dispute }),
  FetchDisputesSuccessfulAction: payload => ({ type: actionTypes.FetchDisputesSuccessful, payload}),
  FetchMoreDisputeSuccessfulAction: payload => ({ type: actionTypes.FetchMoreDisputeSuccessful, payload}),
  ClearDisputesSuccessfulAction: () => ({ type: actionTypes.ClearDisputesSuccessful }),
  FetchDisputeMessagesSuccessfulAction: (payload) => ({ type: actionTypes.FetchDisputeMessagesSuccessful, payload}),
  FetchMoreDisputeMessagesSuccessfulAction: (payload) => ({ type: actionTypes.FetchMoreDisputeMessagesSuccessful, payload }),
  CreateDisputeMessageSuccessfulAction: (payload) => ({ type: actionTypes.CreateDisputeMessageSuccessful, payload}),
  SetMessageTotalPagesSuccessFulAction: payload => ({ type: actionTypes.SetMessageTotalPagesSuccessFul, payload}),
  ClearDisputeMessagesSuccessfulAction: () => ({type: actionTypes.ClearDisputeMessagesSuccessful}),
};