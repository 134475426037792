import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { generateReferralCode } from "../_redux/AccountCRUD";
import { actions } from "../../Auth";

//components
import { Button } from "../../Components";
//css
import "../../../../_metronic/_assets/css/style.bundle.css";
import "../../../../_metronic/_assets/css/handy.css";

function Referral({
                      user,
                      SetReferralAction,
                      updateToken,
                      ...props
}) {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        const { token, data } = (await generateReferralCode()).data;
        updateToken(token);
        SetReferralAction(data.referralCode);
        setLoading(false);
    }

    const copyAPIKey = () => {
        navigator.clipboard.writeText(user.referralCode ?? "");
    }


    const formik = useFormik({
        initialValues: user,
        onSubmit: handleSubmit
    });

    return (
        <>
            <div className="card">
                <div className="card-header card-header-stretch">
                    <div className="card-title">
                        <h3>Referral Code</h3>
                    </div>
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table
                            className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
                            id="kt_api_keys_table"
                        >
                            <tbody className="fs-6 fw-bold text-gray-600">
                            <tr>
                                <td className="ps-9">Referral Code</td>
                                <td data-bs-target="license" id="api_key" className="ps-0">
                                    <div className="api-input-wrapper">
                                        <input
                                            className="form-control"
                                            value={user.referralCode ?? ""}
                                            type="text"
                                            name="api_key"
                                            readOnly
                                        />
                                        <button
                                            data-action="copy"
                                            className="btn btn-active-color-primary btn-icon btn-sm btn-outline-light btn-copy"
                                            onClick={copyAPIKey}
                                        >
                                            <span className="svg-icon svg-icon-2">
                                              <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                              >
                                                <path
                                                    opacity="0.5"
                                                    d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                                    fill="black"
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                                    fill="black"
                                                />
                                              </svg>
                                            </span>
                                        </button>
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/>
                                            <circle cx="12" cy="12" r="3"/>
                                        </svg>
                                    </div>
                                </td>
                                <td/>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="mb-4 d-flex justify-content-center" >
                        <Button
                            loading={loading}
                            type={"button"}
                            text={"Generate Referral Code"}
                            onClick={formik.handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    const { user } = state.auth;
    return { user };
};

export default connect(mapStateToProps, actions)(Referral);
