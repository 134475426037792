import React, {useState, useCallback} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { connect } from 'react-redux';
import { actions } from '../../Auth/_redux/authRedux';
import { updateUser } from '../../Auth/_redux/authCrud';


function ProfileDetails ({
  user,
  setUser,
  updateToken,
  fulfillUser,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values) => {
    const updatedValues = filterChangedValues(values, user, ["vahlid_id"]);
    
    //  call update route on api
    if (Object.keys(updatedValues).length !== 0) {
      setLoading(true);
      const updateResponseData = await updateUser(updatedValues);
      const { data, token } = updateResponseData;
      updateToken(token);
      fulfillUser(data);
      setLoading(false);
    }
  };

  const profileDetailsSchema = Yup.object().shape({
    firstname: Yup.string().nullable(),
    lastname: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    username: Yup.string().nullable(),
  });

  const filterChangedValues = (values, initialValues, ignoredValues = []) => {
    const results = {};
    Object.keys(initialValues).forEach(key => {
      if(!ignoredValues.includes(key) && initialValues[key] !== values[key]) {
        results[key] = values[key];
      }
    })

    return results;
  }


  const formik = useFormik({
    initialValues: {
      firstname: user.firstname || "",
      lastname: user.lastname || "",
      phone: user.phone || "",
      username: user.username || "",
      vahlid_id: user.vahlid_id || "",
      email: user.email || "",
    },
    validationSchema: profileDetailsSchema,
    onSubmit: useCallback(handleSubmit, [handleSubmit])
  })

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Profile Details</h3>
        </div>
      </div>

      <div id="kt_account_profile_details" className="collapse show">
        <form noValidate className="form">
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Full Name
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      name={"firstname"}
                      value={user.firstname}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="First name"
                      {...formik.getFieldProps("firstname") || ""}
                    />
                    {formik.touched.firstname && formik.errors.firstname && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.firstname}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Last name"
                      name={"lastname"}
                      value={user.lastname}
                      {...formik.getFieldProps("lastname") || ""}
                    />
                    {formik.touched.lastname && formik.errors.lastname && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.lastname}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Contact Phone</span>
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="tel"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Phone number"
                  {...formik.getFieldProps("phone") || ""}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.phone}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Username</span>
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Your username"
                  {...formik.getFieldProps("username") || ""}
                />
                {formik.touched.username && formik.errors.username && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.username}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span>Vahlid ID</span>
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  value={user.vahlid_id}
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Your Vahlid ID"
                  {...formik.getFieldProps("vahlid_id") || ""}
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span>Email</span>
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  name={user.email}
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Your Email"
                  {...formik.getFieldProps("email") || ""}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
              onClick={formik.handleSubmit}
            >
              {!loading && "Save Changes"}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.auth;
  return { user };
};

export default connect(mapStateToProps, actions)(ProfileDetails);
