/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { Nav, Tab } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { connect } from "react-redux";
import { format } from 'date-fns'
import { Link } from 'react-router-dom';

const mapStateToProps = state => {
  const { auth, transactionStore } = state;
  const {
    recent_sale_transactions,
    recent_purchase_transactions,
    recent_completed_transactions,
    recent_pending_transactions,
    recent_cancelled_transactions,
  } = transactionStore;
  return {
    user: auth.user,
    recent_sale_transactions,
    recent_purchase_transactions,
    recent_completed_transactions,
    recent_pending_transactions,
    recent_cancelled_transactions,
  };
}

export default connect(mapStateToProps)(function AdvanceTablesWidget7({
  className,
  user,
  recent_sale_transactions,
  recent_purchase_transactions,
  recent_completed_transactions,
  recent_pending_transactions,
  recent_cancelled_transactions,
  ...props
}) {
  const [key, setKey] = useState("Sales");

  return (
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Recent Transaction(s)
          </span>
          </h3>
          <div className="card-toolbar">
            <Tab.Container defaultActiveKey={key}>
              <Nav
                  as="ul"
                  onSelect={(_key) => setKey(_key)}
                  className="nav nav-pills nav-pills-sm nav-dark-75"
              >
                <Nav.Item className="nav-item" as="li">
                  <Nav.Link
                      eventKey="Sales"
                      className={`nav-link py-2 px-4 ${
                          key === "Sales" ? "active" : ""
                      }`}
                  >
                    Sales
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item" as="li">
                  <Nav.Link
                      eventKey="Purchases"
                      className={`nav-link py-2 px-4 ${
                          key === "Purchases" ? "active" : ""
                      }`}
                  >
                    Purchases
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item" as="li">
                  <Nav.Link
                      eventKey="Completed"
                      className={`nav-link py-2 px-4 ${
                          key === "Completed" ? "active" : ""
                      }`}
                  >
                    Completed
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item" as="li">
                  <Nav.Link
                      eventKey="Pending"
                      className={`nav-link py-2 px-4 ${
                          key === "Pending" ? "active" : ""
                      }`}
                  >
                    Pending
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item" as="li">
                  <Nav.Link
                      eventKey="Cancelled"
                      className={`nav-link py-2 px-4 ${
                          key === "Cancelled" ? "active" : ""
                      }`}
                  >
                    Cancelled
                  </Nav.Link>
                </Nav.Item>

              </Nav>
            </Tab.Container>
          </div>
        </div>
        {/* Body */}
        <hr/>
        <div className="card-body pt-2 pb-0 mt-n3">
          <div className={`tab-content mt-5`} id="myTabTables12">
            {/* begin::Tap pane Sales */}
            <div
                className={`tab-pane fade ${key === "Sales" ? "show active" : ""}`}
            >
              <div className="table-responsive">
                <table className="table table-borderless table-vertical-center">
                  <thead>
                  <tr>
                    <th>Transaction ID</th>
                    <th>Customer</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th/>
                  </tr>
                  </thead>
                  <tbody>
                  {recent_sale_transactions.map((transaction,index) => (
                      <tr key={index}>
                        <td>
                          <Link
                              to={`/transaction/${transaction.trans_id}/status`}
                              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                          >
                            {transaction.trans_id}
                          </Link>
                          <div>
                            <Link to={`/transaction/${transaction.trans_id}/status`}
                                  className="text-muted font-weight-bold text-hover-primary"
                            >
                              View
                            </Link>
                          </div>
                        </td>
                        <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {transaction?.buyer?.username || ""}
                        </span>
                          <span
                              className="text-muted font-weight-bold text-hover-primary">
                            on {format(new Date(transaction.created_at), 'dd.MM.yyyy')}
                          </span>
                        </td>
                        <td >
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {format(new Date(transaction.created_at), 'MMMM dd, yyyy')}
                          </span>
                          <span className="text-muted font-weight-500">
                            {format(new Date(transaction.created_at), 'h:m a')}
                          </span>
                        </td>
                        <td>
                          { transaction.status === "cancelled" ?
                              <span className="label label-lg label-light-danger label-inline">
                                {transaction.status}
                              </span>
                              :
                              <>
                                { transaction.status === "pending" ?
                                    <span className="label label-lg label-light-warning label-inline">
                                  {transaction.status}
                                 </span> :
                                    <span className="label label-lg label-light-success label-inline">
                                  {transaction.status}
                                 </span>
                                }
                              </>
                          }

                        </td>
                        <td className="text-right pr-0">
                          <Link
                              to={`/transaction/${transaction.trans_id}/status`}
                              className="btn btn-icon btn-light btn-sm">
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                  src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Settings-1.svg"
                                  )}
                              ></SVG>
                            </span>
                          </Link>
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end::Tap pane Sales */}

            {/* begin::Tap pane WEEK */}
            <div
                className={`tab-pane fade ${key === "Purchases" ? "show active" : ""}`}
            >
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table table-borderless table-vertical-center">
                    <thead>
                    <tr>
                      <th>Transaction ID</th>
                      <th>Customer</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {recent_purchase_transactions.map((transaction,index) => (
                        <tr key={index}>
                          <td>
                            <Link
                                to={`/transaction/${transaction.trans_id}/status`}
                                className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {transaction.trans_id}
                            </Link>
                            <div>
                              <Link to={`/transaction/${transaction.trans_id}/status`}
                                    className="text-muted font-weight-bold text-hover-primary"
                              >
                                View
                              </Link>
                            </div>
                          </td>
                          <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {transaction?.seller?.username || ""}
                        </span>
                            <span
                                className="text-muted font-weight-bold text-hover-primary">
                            on {format(new Date(transaction.created_at), 'dd.MM.yyyy')}
                          </span>
                          </td>
                          <td >
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {format(new Date(transaction.created_at), 'MMMM dd, yyyy')}
                          </span>
                            <span className="text-muted font-weight-500">
                            {format(new Date(transaction.created_at), 'h:m a')}
                          </span>
                          </td>
                          <td>
                            { transaction.status === "cancelled" ?
                                <span className="label label-lg label-light-danger label-inline">
                                 {transaction.status}
                                </span>
                                :
                                <>
                                  { transaction.status === "pending" ?
                                      <span className="label label-lg label-light-warning label-inline">
                                  {transaction.status}
                                 </span> :
                                      <span className="label label-lg label-light-success label-inline">
                                  {transaction.status}
                                 </span>
                                  }
                                </>
                            }
                          </td>
                          <td className="text-right pr-0">
                            <Link
                                to={`/transaction/${transaction.trans_id}/status`}
                                className="btn btn-icon btn-light btn-sm">
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                  src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Settings-1.svg"
                                  )}
                              ></SVG>
                            </span>
                            </Link>
                          </td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* end::Tap pane Week */}

            {/* begin::Tap pane DAY */}
            <div
                className={`tab-pane fade ${key === "Completed" ? "show active" : ""}`}
            >
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table table-borderless table-vertical-center">
                    <thead>
                    <tr>
                      <th>Transaction ID</th>
                      <th>Customer</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {recent_completed_transactions.map((transaction,index) => (
                        <tr key={index}>
                          <td>
                            <Link
                                to={`/transaction/${transaction.trans_id}/status`}
                                className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {transaction.trans_id}
                            </Link>
                            <div>
                              <Link to={`/transaction/${transaction.trans_id}/status`}
                                    className="text-muted font-weight-bold text-hover-primary"
                              >
                                View
                              </Link>
                            </div>
                          </td>
                          <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {transaction?.seller?.username}
                        </span>
                            <span
                                className="text-muted font-weight-bold text-hover-primary">
                            on {format(new Date(transaction.created_at), 'dd.MM.yyyy')}
                          </span>
                          </td>
                          <td >
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {format(new Date(transaction.created_at), 'MMMM dd, yyyy')}
                          </span>
                            <span className="text-muted font-weight-500">
                            {format(new Date(transaction.created_at), 'h:m a')}
                          </span>
                          </td>
                          <td>
                            { transaction.status === "cancelled" ?
                                <span className="label label-lg label-light-danger label-inline">
                                  {transaction.status}
                                </span>
                                :
                                <>
                                  { transaction.status === "pending" ?
                                      <span className="label label-lg label-light-warning label-inline">
                                  {transaction.status}
                                 </span> :
                                      <span className="label label-lg label-light-success label-inline">
                                  {transaction.status}
                                 </span>
                                  }
                                </>
                            }
                          </td>
                          <td className="text-right pr-0">
                            <Link
                                to={`/transaction/${transaction.trans_id}/status`}
                                className="btn btn-icon btn-light btn-sm">
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                  src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Settings-1.svg"
                                  )}
                              ></SVG>
                            </span>
                            </Link>
                          </td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* end::Tap pane Day */}
            {/* begin::Tap pane DAY */}
            <div
                className={`tab-pane fade ${key === "Pending" ? "show active" : ""}`}
            >
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table table-borderless table-vertical-center">
                    <thead>
                    <tr>
                      <th>Transaction ID</th>
                      <th>Customer</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {recent_pending_transactions.map((transaction,index) => (
                        <tr key={index}>
                          <td>
                            <Link
                                to={`/transaction/${transaction.trans_id}/status`}
                                className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {transaction.trans_id}
                            </Link>
                            <div>
                              <Link to={`/transaction/${transaction.trans_id}/status`}
                                    className="text-muted font-weight-bold text-hover-primary"
                              >
                                View
                              </Link>
                            </div>
                          </td>
                          <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {transaction?.seller?.username}
                        </span>
                            <span
                                className="text-muted font-weight-bold text-hover-primary">
                            on {format(new Date(transaction.created_at), 'dd.MM.yyyy')}
                          </span>
                          </td>
                          <td >
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {format(new Date(transaction.created_at), 'MMMM dd, yyyy')}
                          </span>
                            <span className="text-muted font-weight-500">
                            {format(new Date(transaction.created_at), 'h:m a')}
                          </span>
                          </td>
                          <td>
                            { transaction.status === "cancelled" ?
                                <span className="label label-lg label-light-danger label-inline">
                                  {transaction.status}
                                </span>
                                :
                                <>
                                  { transaction.status === "pending" ?
                                      <span className="label label-lg label-light-warning label-inline">
                                  {transaction.status}
                                 </span> :
                                      <span className="label label-lg label-light-success label-inline">
                                  {transaction.status}
                                 </span>
                                  }
                                </>
                            }
                          </td>
                          <td className="text-right pr-0">
                            <Link
                                to={`/transaction/${transaction.trans_id}/status`}
                                className="btn btn-icon btn-light btn-sm">
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                  src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Settings-1.svg"
                                  )}
                              ></SVG>
                            </span>
                            </Link>
                          </td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* end::Tap pane Day */}
            {/* begin::Tap pane DAY */}
            <div
                className={`tab-pane fade ${key === "Cancelled" ? "show active" : ""}`}
            >
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table table-borderless table-vertical-center">
                    <thead>
                    <tr>
                      <th>Transaction ID</th>
                      <th>Customer</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {recent_cancelled_transactions.map((transaction,index) => (
                        <tr key={index}>
                          <td>
                            <Link
                                to={`/transaction/${transaction.trans_id}/status`}
                                className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >
                              {transaction.trans_id}
                            </Link>
                            <div>
                              <Link to={`/transaction/${transaction.trans_id}/status`}
                                    className="text-muted font-weight-bold text-hover-primary"
                              >
                                View
                              </Link>
                            </div>
                          </td>
                          <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {transaction?.seller?.username}
                        </span>
                            <span
                                className="text-muted font-weight-bold text-hover-primary">
                            on {format(new Date(transaction.created_at), 'dd.MM.yyyy')}
                          </span>
                          </td>
                          <td >
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {format(new Date(transaction.created_at), 'MMMM dd, yyyy')}
                          </span>
                            <span className="text-muted font-weight-500">
                            {format(new Date(transaction.created_at), 'h:m a')}
                          </span>
                          </td>
                          <td>
                            { transaction.status === "cancelled" ?
                                <span className="label label-lg label-light-danger label-inline">
                                  {transaction.status}
                                </span>
                                :
                                <>
                                  { transaction.status === "pending" ?
                                      <span className="label label-lg label-light-warning label-inline">
                                  {transaction.status}
                                 </span> :
                                      <span className="label label-lg label-light-success label-inline">
                                  {transaction.status}
                                 </span>
                                  }
                                </>
                            }
                          </td>
                          <td className="text-right pr-0">
                            <Link
                                to={`/transaction/${transaction.trans_id}/status`}
                                className="btn btn-icon btn-light btn-sm">
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                  src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Settings-1.svg"
                                  )}
                              ></SVG>
                            </span>
                            </Link>
                          </td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* end::Tap pane Day */}

          </div>
        </div>
      </div>
  );
}) ;
