import React from "react";
import PropTypes from "prop-types";
import {Card} from "react-bootstrap";
import ReactLoading from "react-loading";

function MiniPreLoader({
                       height,
                       type,
                       color,
                       loading,
                       children,
                       ...props
                   }){
    return (
        <>
            {
                loading
                    ?
                    <>
                        <Card className={'d-flex justify-content-center align-items-center pt-4'} style={{height}}>
                            <ReactLoading type={type} color={color} height={50} width={50} />
                            <p>Loading...</p>
                        </Card>

                    </>
                    :
                    children
            }
        </>
    );
}

MiniPreLoader.propTypes = {
    height: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default MiniPreLoader;
