import React from 'react';
//importing material-ui
import {
    Zoom,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
} from '@material-ui/core';
//importing material-ui-icons
import CloseIcon from '@material-ui/icons/Close';
//importing styles
import './index.css'

function DialogCustom({ open, close, user, photo }) {

    return (
        <Dialog
            open={open}
            fullScreen
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title-dialogCustom">
                <div>
                    <Avatar src={user.photo}/>
                </div>
                <div>
                    <IconButton edge="end" color="inherit" onClick={close} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Zoom in={open} style={{ transitionDelay: open ? '300ms' : '0ms' }}>
                    <img src={photo} alt="" className="DialogCustom__photo"/>
                </Zoom>
            </DialogContent>
        </Dialog>
    )
}

export default DialogCustom;
