import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PropTypes from "prop-types";


import NoDataIndication from "./NoDataIndication";
const { SearchBar } = Search;

const RemoteTransactionTable = ({
                              loading,
                              columns,
                              data,
                              page,
                              sizePerPage,
                              onTableChange,
                              totalSize,
                              keyField,
                              ...props
                          }) => {
    return     <>
        <div>
            <ToolkitProvider
                keyField={ keyField }
                data={ data }
                columns={ columns }
                search
            >
                {
                    toolkitprops => (
                        <>
                            <SearchBar { ...toolkitprops.searchProps } />,
                            <BootstrapTable
                                remote
                                keyField={keyField}
                                data={ data }
                                columns={ columns }
                                onTableChange={ onTableChange }
                                pagination={ paginationFactory({ page, sizePerPage, totalSize }) }
                                filter={filterFactory()}
                                noDataIndication={ () => <NoDataIndication loading={loading} /> }
                                { ...toolkitprops.baseProps }
                            />
                        </>
                    )
                }
            </ToolkitProvider>
        </div>
    </>

};

RemoteTransactionTable.propTypes = {
    keyField: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    totalSize: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    onTableChange: PropTypes.func.isRequired
};

export default RemoteTransactionTable;